import { Injectable } from "@angular/core";
import { MapsAPILoader } from "@agm/core";
import { Observable } from "rxjs/Observable";
import { Subject } from "rxjs/Subject";
import { of } from "rxjs/observable/of";
import { filter, catchError, tap, map, switchMap } from "rxjs/operators";
import { fromPromise } from "rxjs/observable/fromPromise";
export interface Location {
  lat: number;
  lng: number;
}
declare var google: any;

@Injectable()
export class GeocodeService {
  private geocoder: any;

  constructor(private mapLoader: MapsAPILoader) {}

  private initGeocoder() {
    this.geocoder = new google.maps.Geocoder();
  }

  private waitForMapsToLoad(): Observable<boolean> {
    if (!this.geocoder) {
      return fromPromise(this.mapLoader.load()).pipe(
        tap(() => this.initGeocoder()),
        map(() => true)
      );
    }
    return of(true);
  }

  geocodeAddress(location: string): Observable<Location> {
    return this.waitForMapsToLoad().pipe(
      switchMap(() => {
        return new Observable<Location>((observer) => {
          this.geocoder.geocode({ address: location }, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
              observer.next({
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng(),
              });
            } else {
              observer.next({ lat: 0, lng: 0 });
            }
            observer.complete();
          });
        });
      })
    );
  }

  getZipCode(lat, lng) {
    const zipCode$ = new Observable<string>((observer) =>
      this.geocoder.geocode(
        { latLng: new google.maps.LatLng(lat, lng) },
        ([result], status) => {
          const zip = this.getGeocodeZip(result, status);
          observer.next(zip);
          observer.complete();
        }
      )
    );
    return this.waitForMapsToLoad().pipe(switchMap(() => zipCode$));
  }

  private getGeocodeZip(result, status) {
    if (status != google.maps.GeocoderStatus.OK) return "";
    if (!result) return "";
    const component = result.address_components.find(
      (comp) => comp.types[0] == "postal_code"
    );
    if (!component) return "";
    return component.short_name;
  }
}
