import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Globals } from "./../globals";
import { Router } from "@angular/router";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CarriersService {
  constructor(
    private http: HttpClient,
    private globals: Globals,
    private router: Router
  ) {}

  getById(id: number | string) {
    return this.http
      .get<any>(`${this.globals.apiUrl}/carriers/${id}`)
      .pipe(catchError(this.handleError));
  }

  getAll() {
    return this.http
      .get(`${this.globals.apiUrl}/carriers/getAll`)
      .pipe(catchError(this.handleError));
  }

  getAllWithLoads(obj) {
    return this.http
      .get(`${this.globals.apiUrl}/carriers/getAllWithLoads`, {
        params: {
          obj: obj,
        },
      })
      .pipe(catchError(this.handleError));
  }

  searchCarriers(search) {
    if (search) {
      return this.http
        .get<any>(`${this.globals.apiUrl}/carriers/searchCarriers`, {
          params: { search: search },
        })
        .pipe(map((rsp) => rsp.carriers));
    } else {
      return of([]);
    }
  }

  create(data: any) {
    return this.http
      .post(`${this.globals.apiUrl}/carriers`, data)
      .pipe(catchError(this.handleError));
  }

  update(id: string, data: any) {
    return this.http
      .post(`${this.globals.apiUrl}/carriers/${id}`, data)
      .pipe(catchError(this.handleError));
  }

  add(carrier) {
    return this.http
      .post(`${this.globals.apiUrl}/carriers/add`, {
        carrier: carrier,
      })
      .pipe(catchError(this.handleError));
  }

  edit(carrier) {
    return this.http
      .post(`${this.globals.apiUrl}/carriers/edit`, {
        carrier: carrier,
      })
      .pipe(catchError(this.handleError));
  }

  completeInfo(carrier) {
    return this.http
      .post(`${this.globals.apiUrl}/carriers/completeInfo`, {
        carrier: carrier,
      })
      .pipe(catchError(this.handleError));
  }

  delete(id) {
    return this.http
      .post(`${this.globals.apiUrl}/carriers/delete`, {
        id: id,
      })
      .pipe(catchError(this.handleError));
  }

  getFactoringCompany(id) {
    return this.http
      .get(`${this.globals.apiUrl}/carriers/getFactoringCompany`, {
        params: {
          id,
        },
      })
      .pipe(catchError(this.handleError));
  }

  getFiles(carrier: string) {
    return this.http
      .get(`${this.globals.apiUrl}/carriers/files`, {
        params: { carrier },
      })
      .pipe(catchError(this.handleError));
  }

  uploadFiles(files: File[]) {
    const fd = new FormData();
    files.forEach((file) => fd.append("files", file));
    return this.http
      .post(`${this.globals.apiUrl}/carriers/files`, fd)
      .pipe(catchError(this.handleError));
  }

  deleteFile(id: string) {
    return this.http
      .delete(`${this.globals.apiUrl}/carriers/file`, { params: { id } })
      .pipe(catchError(this.handleError));
  }

  downloadFile(uuid: string) {
    return this.http
      .get(`${this.globals.apiUrl}/carriers/file`, {
        params: { uuid },
        responseType: "blob",
      })
      .map((res: Blob) => {
        return res;
      });
  }

  saveDownloadedFile(data) {
    const file = new Blob([data], { type: data.type });
    const url = URL.createObjectURL(file);
    window.open(url);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(`Backend returned error ${error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
}
