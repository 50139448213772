import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Globals } from "./../globals";
import { Router } from "@angular/router";
import { of, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
@Injectable({
  providedIn: "root",
})
export class UsuariosService {
  constructor(
    private http: HttpClient,
    private globals: Globals,
    private router: Router
  ) {}

  getAll() {
    // return this.http.get(`${this.globals.apiUrl}/usuarios?status=activo`).pipe(catchError(this.handleError));
    return this.http
      .get<any[]>(`${this.globals.apiUrl}/usuarios/getAll`)
      .pipe(catchError(this.handleError));
  }

  getDispatchers() {
    return this.http
      .get(`${this.globals.apiUrl}/usuarios/getDispatchers`)
      .pipe(catchError(this.handleError));
  }

  searchAccountingUsers(search: string) {
    if (search) {
      return this.http.get<any>(`${this.globals.apiUrl}/usuarios/searchAccountingUser`, {
        params: { search: search }
      }).pipe(
        map((res: any) => {
          return res.accounting.map((user: any) => {
            user.fullName = `${user.name} ${user.lastName}`;
            return user;
          });
        }),
        catchError(this.handleError)
      );
    } else {
      return of([]);
    }
  }

  searchManagersUsers(search: string) {
    if (search) {
      return this.http.get<any>(`${this.globals.apiUrl}/usuarios/searchManagerUser`, {
        params: { search: search }
      }).pipe(
        map((res: any) => {
          return res.managers.map((manager: any) => {
            manager.fullName = `${manager.name} ${manager.lastName}`;
            return manager;
          });
        }),
        catchError(this.handleError)
      );
    } else {
      return of([]);
    }
  }

  agregar(usuario) {
    return this.http
      .post(`${this.globals.apiUrl}/usuarios/agregar`, {
        usuario: usuario,
      })
      .pipe(catchError(this.handleError));
  }

  editar(id, data) {
    return this.http
      .post(`${this.globals.apiUrl}/usuarios/editar/${id}`, {
        ...data,
      })
      .pipe(catchError(this.handleError));
  }

  eliminar(id) {
    return this.http
      .post(`${this.globals.apiUrl}/usuarios/eliminar`, {
        id: id,
      })
      .pipe(catchError(this.handleError));
  }

  guardarPermisos(usuario) {
    return this.http
      .post(`${this.globals.apiUrl}/usuarios/permisos`, {
        usuario: usuario,
      })
      .pipe(catchError(this.handleError));
  }

  changePassword(usuario, oldPwd, newPwd) {
    return this.http
      .post(`${this.globals.apiUrl}/usuarios/changePassword`, {
        usuario: usuario,
        password: oldPwd,
        newPassword: newPwd,
      })
      .pipe(catchError(this.handleError));
  }

  signOut() {
    localStorage.removeItem("jwtToken");
    this.router.navigate(["/login"]);
  }

  isLoggedIn() {
    if (localStorage.getItem("jwtToken")) {
      return true;
    } else {
      return false;
    }
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("jwtToken")).user;
  }

  getToken() {
    return JSON.parse(localStorage.getItem("jwtToken")).token;
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(`Backend returned error ${error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
}
