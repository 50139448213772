import { Component, OnDestroy, OnInit } from "@angular/core";
import { IncomingLoadsService } from "app/services/incoming-loads.service";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Subject, of } from "rxjs";
import { finalize, switchMap, takeUntil, tap } from "rxjs/operators";

export const VIEW_INCOMING_LOAD_MODAL_ID = "VIEW_INCOMING_LOAD_MODAL";

@Component({
  selector: "app-view-incoming-load-modal",
  templateUrl: "./view-incoming-load-modal.component.html",
  styleUrls: ["./view-incoming-load-modal.component.css"],
})
export class ViewIncomingLoadModalComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();
  private readonly data$ = (id: number) =>
    of({}).pipe(
      tap(() => (this.loading = true)),
      switchMap(() => this._incomingLoads.getById(id)),
      takeUntil(this.onDestroy$),
      finalize(() => (this.loading = false))
    );

  public loading: boolean;
  public data: any;
  public message: string;

  constructor(
    private _incomingLoads: IncomingLoadsService,
    private _modal: NgxSmartModalService
  ) {}

  ngOnInit() {
    this.getData();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getData() {
    const { id } = this._modal.get(VIEW_INCOMING_LOAD_MODAL_ID).getData();
    if (!id) return;
    this.data$(id).subscribe((data) => {
      this.data = {...data, title: decodeURIComponent(data.title)};
      if (!data) this.message = "No scheduled load found.";
    });
  }
}
