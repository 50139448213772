
import { Component, OnInit, Input } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-visualize-pdf',
  templateUrl: './visualize-pdf.component.html',
  styleUrls: ['./visualize-pdf.component.css']
})
export class VisualizePdfComponent implements OnInit {
  @Input() pdfBlob = null;
  constructor(
    private modal: NgxSmartModalService,
    
    ) { 
    }

  ngOnInit() {
  }
  openModal() {
    this.modal.get('modalPdf').open();
  }

}
