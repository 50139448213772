import { Component } from '@angular/core';

//Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  children?: ChildrenItems[];
  permission?: string;
  description?: string;
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab?: string;
  type?: string;
  permission?: string;
  icontype: string;
  description?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Inicio',
    type: 'link',
    icontype: 'nc-icon nc-bank',
    description: 'Dashboard'
  },
  {
    path: '/accounting',
    title: 'Accounting',
    type: 'sub',
    icontype: 'fal fa-money-bill-alt',
    permission: "ACCOUNTING",
    children: [
      { path: 'customers', title: 'By customer', icontype: 'fal fa-user-friends', permission: "ACCOUNTING", description: 'By customer' },
      { path: 'carriers', title: 'By carriers', icontype: 'fal fa-address-card', permission: "ACCOUNTING", description: 'By carriers' }
    ]
  },
  {
    path: '/pre-loads',
    title: 'Preloads',
    type: 'link',
    icontype: 'fal fa-truck-loading',
    children: [
      { path: 'new-pre-load', title: 'New preload', icontype: '', permission: "PRELOAD", description: 'New preload' },
      { path: 'edit', title: 'Edit preload', icontype: '', permission: "PRELOAD", description: 'Edit preload' },
    ],
    permission: "PRELOADS",
    description: 'Preloads'
  },
  {
    path: '/loads',
    title: 'Loads',
    type: 'link',
    icontype: 'fal fa-truck-loading',
    // ** NOTA: este atributo children SOLO ESTÁ AQUI para poder mostrar el title de la ruta en el navbar,
    //no sirve para otra cosa, por eso no tiene icono ni nada
    children: [
      { path: 'detail', icontype: '', title: '', description: 'New load' },
      { path: 'convert', icontype: '', title: '', description: 'Convert preload to load' },
      { path: 'edit', icontype: '', title: '', description: 'Edit load' },
    ],
    permission: "LOADS",
    description: 'Loads'
  },
  {
    path: '/commissions',
    title: 'Commissions',
    type: 'link',
    icontype: 'fal fa-badge-percent',
    children: [
      { path: '', title: 'Commissions list', icontype: '', permission: "COMMISSIONS", description: 'Commissions list' },
      { path: 'new', title: 'New commissions', icontype: '', permission: "COMMISSIONS", description: 'New commissions' },
    ],
    permission: "COMMISSIONS",
    description: 'Commissions for dispatchers & operators'
  },
  {
    path: '/sales',
    title: 'Sales',
    type: 'link',
    icontype: 'fal fa-dollar-sign',
    permission: "SALES",
    description: 'Sales',
  },
  {
    path: '/pricing',
    title: 'Pricing',
    type: 'link',
    icontype: 'fal fa-hands-usd',
    permission: "PRICING",
    description: 'Pricing',
  },
  {
    path: '/reportes',
    title: 'Reports',
    type: 'sub',
    icontype: 'fal fa-user-chart',
    children: [
      { path: 'stadistics', title: 'Advance stadistics', icontype: 'fal fa-address-card', permission: "ADVANCE STADISTICS", description: 'Advance Stadistics' },
      { path: 'customers', title: 'Customers report', icontype: 'fal fa-address-card', permission: "CUSTOMERS REPORT", description: 'Customers report' },
      { path: 'carriers', title: 'Carriers report', icontype: 'fal fa-address-card', permission: "CARRIERS REPORT", description: 'Carriers report' },
      { path: 'dispatchers', title: 'Dispatchers report', icontype: 'fal fa-address-card', permission: "DISPATCHERS REPORT", description: 'Dispatchers report' },
      { path: 'manage-reports', title: 'Manage Report', icontype: 'fal fa-address-card', permission: "MANAGE REPORT", description: 'Manage report' },
      { path: 'operators-report', title: 'Operators Report', icontype: 'fal fa-address-card', permission: "OPERATORS REPORT", description: 'Operators Report' },
      { path: 'activity-log-report', title: 'Activity Log', icontype: 'fal fa-list-ol', permission: "ACTIVITY LOG", description: 'Activity Log' },

    ],
    permission: "USERS",
  },
  {
    path: '/catalogos',
    title: 'Catalogues',
    type: 'sub',
    icontype: 'fal fa-archive',
    permission: "USERS",
    children: [
      { path: 'accounts', title: 'Accounts', icontype: 'fal fa-hand-holding-box', permission: "ACCOUNTS", description: 'Accounts' },
      { path: 'branches', title: 'Branches', icontype: 'fal fa-building', permission: "BRANCHES", description: 'Branches' },
      { path: 'carriers', title: 'Carriers', icontype: 'fal fa-address-card', permission: "CARRIERS", description: 'Carriers' },
      { path: 'consignees', title: 'Consignees', icontype: 'fal fa-hand-holding-box', permission: "CONSIGNEES", description: 'Consignees' },
      { path: 'customers', title: 'Customers', icontype: 'fal fa-user-friends', permission: "CUSTOMERS", description: 'Customers' },
      { path: 'factoringCompanies', title: 'Factoring companies', icontype: 'fal fa-building', permission: "FACTORING COMPANIES", description: 'Factoring companies' },
      { path: 'sellers', title: 'Sales representative', icontype: 'fal fa-hands-helping', permission: "SALES PERSON", description: 'Sales representative' },
      { path: 'shippers', title: 'Shippers', icontype: 'fal fa-hand-holding-box', permission: "SHIPPERS", description: 'Shippers' },
      { path: 'routes-mx', title: 'Routes Mx', icontype: 'fal fa-route', permission: "ROUTES MX", description: 'Routes Mx' },
      { path: 'users', title: 'Users', icontype: 'fal fa-users', permission: "USERS", description: 'Users' },
      { path: 'warehouses', title: 'Warehouses', icontype: 'fal fa-warehouse', permission: "WAREHOUSES", description: 'Warehouses' },
      { path: 'work_orders', title: 'Work orders', icontype: 'fal fa-file-exclamation', permission: "WORK ORDERS", description: 'Work orders' }
    ]
  },
  {
    path: '/config',
    title: 'Config',
    type: 'link',
    icontype: 'fal fa-cog',
    permission: "CONFIGURATION",
    description: 'Config'
  }
];

@Component({

  selector: 'sidebar-cmp',
  templateUrl: 'sidebar.component.html',
})

export class SidebarComponent {
  public menuItems: any[];
  isNotMobileMenu() {
    if (window.outerWidth > 991) {
      return false;
    }
    return true;
  }
  private token = JSON.parse(localStorage.getItem('jwtToken'));
  public perm = this.token.user.permisos;
  public user = this.token.user;

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
}
