import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Globals } from "app/globals";
import { FactoringCompaniesService } from "app/services/factoring-companies.service";
import { UsuariosService } from "app/services/usuarios.service";
import { ToastrManager } from "ng6-toastr-notifications";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Subject } from "rxjs";
import { finalize, takeUntil } from "rxjs/operators";

export const CREATE_FACTORING_COMPANY_MODAL = "CREATE_FACTORING_COMPANY";

@Component({
  selector: "app-create-factoring-company",
  templateUrl: "./create-factoring-company.component.html",
  styleUrls: ["./create-factoring-company.component.css"],
})
export class CreateFactoringCompanyComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<void>();

  public bsConfig: Partial<BsDatepickerConfig> = {
    containerClass: "theme-default",
    maxDate: new Date(),
  };

  //Status
  public loading: boolean;
  public requesting: boolean;

  //Data
  public data: any;
  public paymentMethods = this.globals.paymentMethods;

  //Forms
  public form: FormGroup;

  constructor(
    private _factoringCompany: FactoringCompaniesService,
    private fb: FormBuilder,
    private _modal: NgxSmartModalService,
    private _toast: ToastrManager,
    private globals: Globals,
    private _usuarios: UsuariosService
  ) {}

  get disabled() {
    return this.form.invalid || this.requesting || this.loading;
  }

  ngOnInit() {
    this.form = this.fb.group({
      name: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      telephone: ["", Validators.required],
      paymentMethod: [null, Validators.required],
    });

    this.getData();
    this.initListeners();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  initListeners() {}

  getData() {
    const { id } = this._modal.getModalData(CREATE_FACTORING_COMPANY_MODAL);
    if (!id) return;
    this.loading = true;
    this._factoringCompany
      .getById(id)
      .pipe(
        finalize(() => (this.loading = false)),
        takeUntil(this.onDestroy$)
      )
      .subscribe(
        (data) => {
          this.data = data;
          this.form.patchValue(data);
        },
        (error) =>
          this._toast.errorToastr("Error getting factory company.", "Error", {
            maxShown: 1,
            animate: "slideFromBottom",
          })
      );
  }

  onSubmit() {
    if (this.form.invalid)
      return this._toast.errorToastr("Invalid Form.", "Error", {
        maxShown: 1,
        animate: "slideFromBottom",
      });
    if (this.data) this.update();
    else this.create();
  }

  create() {
    this.requesting = true;
    this._factoringCompany
      .add(
        JSON.stringify({
          ...this.form.getRawValue(),
          user: this._usuarios.getCurrentUser()["id"],
        })
      )
      .pipe(
        finalize(() => (this.requesting = false)),
        takeUntil(this.onDestroy$)
      )
      .subscribe(
        (data) => {
          this._toast.successToastr(
            "Factoring company added successfully.",
            "",
            {
              maxShown: 1,
              animate: "slideFromBottom",
            }
          );
          this._modal
            .get(CREATE_FACTORING_COMPANY_MODAL)
            .removeData()
            .setData({ data })
            .close();
        },
        (error) =>
          this._toast.errorToastr(
            "Error adding factoring company, try again.",
            "",
            {
              maxShown: 1,
              animate: "slideFromBottom",
            }
          )
      );
  }

  update() {
    this.requesting = true;
    this._factoringCompany
      .edit(
        JSON.stringify({
          ...this.form.getRawValue(),
          id: this.data.id,
        })
      )
      .pipe(
        finalize(() => (this.requesting = false)),
        takeUntil(this.onDestroy$)
      )
      .subscribe(
        (data) => {
          this._toast.successToastr(
            "Factoring company edited successfully.",
            "",
            {
              maxShown: 1,
              animate: "slideFromBottom",
            }
          );
          this._modal
            .get(CREATE_FACTORING_COMPANY_MODAL)
            .removeData()
            .setData({ data })
            .close();
        },
        (error) =>
          this._toast.errorToastr(
            "Error editing factoring company, try again.",
            "",
            {
              maxShown: 1,
              animate: "slideFromBottom",
            }
          )
      );
  }

  close() {
    this._modal.get(CREATE_FACTORING_COMPANY_MODAL).removeData().close();
  }
}
