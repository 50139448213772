import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Globals } from "app/globals";
import { CarriersService } from "app/services/carriers.service";
import { FilesService } from "app/services/files.service";
import { LoadsService } from "app/services/loads.service";
import { UsuariosService } from "app/services/usuarios.service";
import { FileUploader } from "ng2-file-upload";
import { ToastrManager } from "ng6-toastr-notifications";
import { NgxSmartModalService } from "ngx-smart-modal";
import { of, Subject } from "rxjs";
import { finalize, switchMap, takeUntil, tap } from "rxjs/operators";
import { PdfModalComponent } from "../pdf-modal/pdf-modal.component";
import { CustomersService } from "app/services/customers.service";

@Component({
  selector: "attach-files-modal",
  templateUrl: "./attach-files-modal.component.html",
  styleUrls: ["./attach-files-modal.component.css"],
})
export class AttachFilesModalComponent implements OnInit, OnDestroy {
  public readonly MODAL_ID = "ATTACH_FILES_MODAL";
  public readonly DELETE_FILE_MODAL_ID = "DELETE_FILE_MODAL";
  private readonly onDestroy$ = new Subject<void>();
  public data: any;
  public title: string;
  public type: "load" | "carrier" | "customer";
  public files: any[] = [];
  public selectedFile: any;
  public loading = false;
  public requesting = false;
  public uploader: FileUploader;
  public fileOverDZ = false;
  public hasAnotherDropZoneOver: boolean = false;

  public types = {
    carrier: {
      url: `${this.globals.apiUrl}/carriers/files`,
      download: (uuid) => this._carriers.downloadFile(uuid),
      get: (carrier) => this._carriers.getFiles(carrier),
      delete: (file) => this._carriers.deleteFile(file),
    },
    load: {
      url: `${this.globals.apiUrl}/loads/uploadFiles`,
      download: (obj) => this._loads.downloadAccountingFile(obj),
      get: (load) => this._loads.getUploadedFilesDispatch(load),
      delete: (file) => this._loads.deleteUploadFile(file),
    },
    customer: {
      url: `${this.globals.apiUrl}/customers/files`,
      download: (uuid) => this._customers.downloadFile(uuid),
      get: (customer) => this._customers.getFiles(customer),
      delete: (file) => this._customers.deleteFile(file),
    },
  };

  //Observables
  private readonly downloadUploadedFile$ = (idx) =>
    of({}).pipe(
      tap(() => (this.requesting = true)),
      switchMap(() => {
        this.requesting = true;
        let data: any;
        switch (this.type) {
          case "carrier":
          case "customer":
            data = this.files[idx].uuid;
            break;
          case "load":
            data = JSON.stringify(this.files[idx]);
            break;
          default:
            throw "No data";
        }
        return this.types[this.type].download(data);
      }),
      finalize(() => (this.requesting = false)),
      takeUntil(this.onDestroy$)
    );
  private readonly deleteUploadedFile$ = (file) =>
    of({}).pipe(
      tap(() => (this.requesting = true)),
      switchMap(() => {
        return this.types[this.type].delete(file.id);
      }),
      finalize(() => (this.requesting = false)),
      takeUntil(this.onDestroy$)
    );
  private readonly getUploadedFiles$ = () =>
    of({}).pipe(
      tap(() => (this.loading = true)),
      switchMap(() => {
        if (!this.data) throw `No selected ${this.type}.`;
        return this.types[this.type].get(this.data);
      }),
      finalize(() => (this.loading = false)),
      takeUntil(this.onDestroy$)
    );

  constructor(
    private _loads: LoadsService,
    private _toast: ToastrManager,
    private _modal: NgxSmartModalService,
    private globals: Globals,
    private _usuarios: UsuariosService,
    private _files: FilesService,
    private _carriers: CarriersService,
    private _customers: CustomersService,
  ) {}

  ngOnInit() {
    this.initListeners();
    this.initUploader();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  initUploader() {
    this.uploader = new FileUploader({
      url: this.types[this.type].url,
      authToken: `JWT ${this._usuarios.getToken()}`,
      isHTML5: true,
      removeAfterUpload: true,
    });
    this.uploader.onBuildItemForm = (_, form: any) => {
      form.append(this.type, this.data);
      if (this.type == "load") form.append("isFromDispatch", true);
    };
    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
    };
    this.uploader.onSuccessItem = () => {
      this._toast.successToastr("File uploaded.");
      this.getUploadedFiles();
    };
  }

  //Listeners
  initListeners() {
    this.onDataChange();
  }

  onDataChange() {
    const modal = this._modal.get(this.MODAL_ID);
    this.initData();
    modal.onDataAdded.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.initData();
    });
    modal.onDataRemoved.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.resetData();
    });
  }

  initData() {
    const modal = this._modal.get(this.MODAL_ID);
    const { data, title, type } = modal.getData();
    this.title = title;
    this.data = data;
    this.type = type;
    this.getUploadedFiles();
  }

  resetData() {
    this.title = null;
    this.data = null;
    this.type = null;
    this.files = [];
    this.selectedFile = null;
  }
  //Requests
  getUploadedFiles() {
    this.getUploadedFiles$().subscribe(
      (files: any) => (this.files = files),
      (error) =>
        this._toast.errorToastr(
          "Error trying to get the uploaded files, verify your internet connection."
        )
    );
  }

  openPdfModal(idx) {
    this.downloadUploadedFile$(idx).subscribe(
      (data) => {
        const filename = this.files[idx].name;
        const ext = filename.substring(
          filename.lastIndexOf(".") + 1,
          filename.length
        );
        if (ext != "pdf") return this._files.openFile(data);

        this.pdfModal(new Blob([data]));
      },
      (error) =>
        this._toast.errorToastr(
          "Error trying to download file, verify your internet connection."
        )
    );
  }

  pdfModal(pdf) {
    const modal = this._modal.create("PDF_MODAL", PdfModalComponent, {
      customClass: "pdf-preview",
      dismissable: false,
      closable: true,
    });
    modal.removeData();
    modal.setData({ pdf });
    modal.open();
  }

  downloadUploadedFile(idx) {
    this.downloadUploadedFile$(idx).subscribe(
      (data) => {
        this._files.saveDownloadedFile(data, this.files[idx].name);
        this._toast.successToastr("File downloaded.");
      },
      () =>
        this._toast.errorToastr(
          "Error trying to download file, verify your internet connection."
        )
    );
  }

  openDeleteFileModal(idx) {
    this.selectedFile = this.files[idx];
    this._modal.get(this.DELETE_FILE_MODAL_ID).open();
  }

  deleteUploadedFile() {
    this.deleteUploadedFile$(this.selectedFile).subscribe(
      () => {
        this._toast.successToastr("File deleted");
        this.selectedFile = null;
        this.closeDeleteFileModal();
        this.getUploadedFiles();
      },
      (error) => this._toast.errorToastr(error)
    );
  }

  //Modals
  closeModal() {
    this._modal.get(this.MODAL_ID).close();
  }

  closeDeleteFileModal() {
    this.selectedFile = null;
    this._modal.get(this.DELETE_FILE_MODAL_ID).close();
  }
}
