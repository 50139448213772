import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadsService } from 'app/services/loads.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Subject, of } from 'rxjs';
import { finalize, switchMap, takeUntil, tap } from "rxjs/operators";
export const DUPLICATE_LOAD_MODAL = "DUPLICATE_LOAD_MODAL";

@Component({
  selector: 'app-duplicate-load-modal',
  templateUrl: './duplicate-load-modal.component.html',
  styleUrls: ['./duplicate-load-modal.component.css']
})
export class DuplicateLoadModalComponent implements OnInit {
  public form: FormGroup;
  public load: any;
  public loading: boolean;
  public clonedLoads = [];
  public cloned = false;
  private readonly onDestroy$ = new Subject<void>();

  constructor(
    private _fb: FormBuilder,
    private _modal: NgxSmartModalService,
    private _loads: LoadsService,
    private _toast: ToastrManager,
  ) { }

  private readonly cloneLoad$ = () =>
    of({}).pipe(
      tap(() => (this.loading = true)),
      switchMap(() => {
        const { quantity } = this.form.value;
        return this._loads.cloneLoad(quantity, this.load.id);
      }),
      finalize(() => (this.loading = false)),
      takeUntil(this.onDestroy$)
    );

  ngOnInit() {
    this.form = this._fb.group({
      quantity: [null, [Validators.required]]
    });
    this.getData();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getData() {
    const { load } = this._modal
      .get(DUPLICATE_LOAD_MODAL)
      .getData();
      this.load = load;
  }

  invalidControl(name: string) {
    return this.form.get(name).invalid && this.form.get(name).touched;
  }

  closeModal() {
    this._modal.get(DUPLICATE_LOAD_MODAL).close();
  }

  cloneLoad() {
    this.cloneLoad$().subscribe(
      (clonedElements: any) => {
        this._toast.successToastr("load cloned successfully.");
        this._modal.get(DUPLICATE_LOAD_MODAL).removeData();
        this._modal.get(DUPLICATE_LOAD_MODAL).setData({ cloned: true });

        this.cloned = true;
        this.clonedLoads = clonedElements;
        this.form.disable();
      },
      (error) => this._toast.errorToastr(error)
    );
  }
}
