import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { FilesService } from "app/services/files.service";
import { LoadsService } from "app/services/loads.service";
import { UsuariosService } from "app/services/usuarios.service";
import { ToastrManager } from "ng6-toastr-notifications";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Subject, of } from "rxjs";
import { finalize, takeUntil, switchMap } from "rxjs/operators";
import { ConfigService } from "app/services/config.service";
import {
  PDF_MODAL_ID,
  PdfModalComponent,
} from "../../pdf-modal/pdf-modal.component";

export const SEND_RATE_MODAL_ID = "SEND_RATE_MODAL";

@Component({
  selector: "send-rate-modal",
  templateUrl: "./send-rate-modal.component.html",
})
export class SendRateModalComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<void>();

  //Status
  public downloading = false;
  public requesting = false;
  public loading = false;

  //Data
  public data: any;
  public loadNumber: string;
  public loadCarrier: number;
  public type: string;
  public availableRecipients: any[];

  //Forms
  public mailForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private _modal: NgxSmartModalService,
    private _toast: ToastrManager,
    private _loads: LoadsService,
    private _usuarios: UsuariosService,
    private _files: FilesService,
    private _config: ConfigService
  ) {}

  get recipient() {
    return this.mailForm.controls["recipient"].value;
  }

  get disabled() {
    return this.mailForm.invalid || this.requesting;
  }

  ngOnInit() {
    this.initForms();
    this.initListeners();
    this.initData();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  initForms() {
    this.mailForm = this.fb.group({
      recipient: [null, Validators.required],
      recipientMails: [null, Validators.required],
      sendMeAcopy: [true, Validators.required],
      subject: [null, Validators.required],
      message: [null, Validators.required],
    });
  }

  initListeners() {
    this.onRecipientChanges();
  }

  onRecipientChanges() {
    this.mailForm.controls["recipient"].valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((recipient) => {
        const { recipientMails } = this.mailForm.controls;
        recipientMails.patchValue("");
        if (!recipient) return;
        recipientMails.patchValue(
          recipient.type == "Carrier"
            ? recipient.carrier.email
            : recipient.warehouse.email
        );
      });
  }

  initData() {
    const modal = this._modal.get(SEND_RATE_MODAL_ID);
    const { loadId, loadNumber, loadCarrierId, type } = modal.getData();
    this.loadNumber = loadNumber || loadId;
    this.loadCarrier = loadCarrierId;
    this.type = type;
    this.getData(loadId);
  }

  //Req
  getData(loadId: number) {
    this.loading = true;

    this._loads
    .getById(JSON.stringify({ id: loadId, type: "detail" }))
    .pipe(
      switchMap((load: any) => {
        return this._config.getConfig().pipe(
          switchMap((config: any) => of({ load, config }))
        );
      }),
      finalize(() => (this.loading = false)),
      takeUntil(this.onDestroy$)
    )
    .subscribe(
      ({ load, config }) => {
        this.data = load;
        let recipient = null;

        if (this.loadCarrier && this.type) {
          const lc = load[
            this.type == "warehouse"
              ? "warehousesPopulated"
              : "carriersPopulated"
          ].find(({ id }) => id == this.loadCarrier);
          recipient = {
            ...lc,
            type: this.type == "warehouse" ? "Warehouse" : "Carrier",
            displayName: lc[this.type].name,
            idRecipient: `${this.type == "warehouse" ? "W" : "C"}-${lc.id}`,
          };
        } else {
          const carriers = load.carriersPopulated.map((lc) => ({
            ...lc,
            type: "Carrier",
            displayName: lc.carrier.name,
            idRecipient: `C-${lc.id}`,
          }));
          const warehouses = load.warehousesPopulated.map((lw) => ({
            ...lw,
            type: "Warehouse",
            displayName: lw.warehouse.name,
            idRecipient: `W-${lw.id}`,
          }));
          this.availableRecipients = [...carriers, ...warehouses];
        }

        const { name, lastName } = this._usuarios.getCurrentUser();
        this.mailForm.patchValue({
          recipient,
          sendMeAcopy: true,
          subject: `${config.name} - Rate Confirmation #${load.loadNumber}`,
          message: `Hello,\n I have sent you the rate confirmation for load #${load.loadNumber} \n Regards, \n ${name} ${lastName}\n ${config.name}\n`,
        });
      },
      (error) => this._toast.errorToastr(error)
    );
  }

  sendMail() {
    if (this.mailForm.invalid) return this._toast.errorToastr("Error in form.");

    this.requesting = true;

    const { email } = this._usuarios.getCurrentUser();

    this._loads
      .sendMails({ ...this.mailForm.value, userSendingMail: email })
      .pipe(
        finalize(() => (this.requesting = false)),
        takeUntil(this.onDestroy$)
      )
      .subscribe(
        () => {
          this._toast.successToastr("Mail sent successfully.");
          this.closeModal();
        },
        (error) => this._toast.errorToastr(error)
      );
  }

  downloadFile(recipient) {
    this.downloading = true;
    this._loads
      .downloadFile(this.data.id, recipient.idRecipient)
      .pipe(
        finalize(() => (this.downloading = false)),
        takeUntil(this.onDestroy$)
      )
      .subscribe(
        (data: any) => {
          this._toast.successToastr("File downloaded.");
          this._files.saveDownloadedFile(
            data,
            `rate_confirmation_${this.loadNumber}${
              this.data.isMultiCarriers ? `_${recipient.idx + 1}` : ""
            }.pdf`
          );
        },
        (error) =>
          this._toast.errorToastr(
            "Error trying to download file, verify your internet connection."
          )
      );
  }

  openPdfModalMail(recipient) {
    this.downloading = true;
    this._loads
      .downloadFile(this.data.id, recipient.idRecipient)
      .pipe(
        finalize(() => (this.downloading = false)),
        takeUntil(this.onDestroy$)
      )
      .subscribe(
        (data) => {
          let file = new Blob([data]);
          this.pdfModal(file);
        },
        (error) =>
          this._toast.errorToastr(
            "Error trying to download file, verify your internet connection."
          )
      );
  }

  pdfModal(pdf) {
    this._modal
      .create(PDF_MODAL_ID, PdfModalComponent, {
        customClass: "pdf-preview",
        dismissable: false,
        closable: true,
      })
      .removeData()
      .setData({ pdf })
      .open()
      .onAnyCloseEvent.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this._modal.removeModal(PDF_MODAL_ID);
      });
  }
  //Modal
  closeModal() {
    this._modal.get(SEND_RATE_MODAL_ID).close();
  }

  //Utils
  invalidControl(control: AbstractControl) {
    return control.invalid && control.touched;
  }
}
