import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { HttpModule } from "@angular/http";
import { CurrencyPipe } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppComponent } from "./app.component";

import { SidebarModule } from "./shared/sidebar/sidebar.module";
import { FooterModule } from "./shared/footer/footer.module";
import { NavbarModule } from "./shared/navbar/navbar.module";
import { AdminLayoutComponent } from "./layouts/admin/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth/auth-layout.component";
import { AppRoutes } from "./app.routing";
import { JwtInterceptor } from "./http.interceptor";
import { ErrorInterceptor } from "./error.interceptor";
import { AuthGuard } from "./auth.guard";
import { LoginComponent } from "./components/login/login.component";
import { Globals } from "./globals";
// no son de la plantilla
import { AngularTokenModule } from "angular-token";
import { NgxPermissionsModule } from "ngx-permissions";
import { ToastrModule } from "ng6-toastr-notifications";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { NgSelectModule } from "@ng-select/ng-select";
import { FileUploadModule } from "ng2-file-upload";
import { AgmCoreModule } from "@agm/core";
import { GeocodeService } from "./services/geocode.service";
import { AttachFilesModalModule } from "./shared/modals/attach-files-modal/attach-files-modal.module";
import { NewLogModalModule } from "./shared/modals/new-log-modal/new-log-modal.module";
import { LoadCarrierMsgComponent } from "./shared/load-carrier-msg/load-carrier-msg.component";
import { LoadCarrierMsgModule } from "./shared/load-carrier-msg/load-carrier-msg.module";
import { TextlocateModule } from "./shared/textlocate/textlocate.module";
import { TextlocateComponent } from "./shared/textlocate/textlocate.component";
import { TextlocateHistoryComponent } from "./shared/textlocate/textlocate-history/textlocate-history.component";
import { TextlocateLocateComponent } from "./shared/textlocate/textlocate-locate/textlocate-locate.component";
import { CalendarModalComponent } from "./shared/modals/calendar-modal/calendar-modal.component";
import { CalendarModalModule } from "./shared/modals/calendar-modal/calendar-modal.module";
import { CreateIncomingLoadModalModule } from "./shared/modals/create-incoming-load-modal/create-incoming-load-modal.module";
import { CreateIncomingLoadModalComponent } from "./shared/modals/create-incoming-load-modal/create-incoming-load-modal.component";
import { ViewIncomingLoadModalModule } from "./shared/modals/view-incoming-load-modal/view-incoming-load-modal.module";
import { ViewIncomingLoadModalComponent } from "./shared/modals/view-incoming-load-modal/view-incoming-load-modal.component";
import { ConfirmationModalModule } from "./shared/modals/confirmation-modal/confirmation-modal.module";
import { ConfirmationModalComponent } from "./shared/modals/confirmation-modal/confirmation-modal.component";
import { DuplicateLoadModalModule } from "./shared/modals/duplicate-load-modal/duplicate-load-modal.module";
import { DuplicateLoadModalComponent } from './shared/modals/duplicate-load-modal/duplicate-load-modal.component';
import { ActivityLogDetailModule } from "./shared/modals/activity-log-detail/activity-log-detail.module";
import { ActivityLogDetailComponent } from './shared/modals/activity-log-detail/activity-log-detail.component';
import { CreateRouteMxComponent } from "./components/catalogos/routes-mx/components/create-route-mx/create-route-mx.component";
import { CreateRouteMxModule } from "./components/catalogos/routes-mx/components/create-route-mx/create-route-mx.module";
import { CreateSaleComponent } from "./components/sales/components/create-sale/create-sale.component";
import { CreateSaleModule } from "./components/sales/components/create-sale/create-sale.module";
import { LoadTrailersModule } from "./components/loads/components/load-trailers/load-trailers.module";
import { MarsItemsModule } from "./components/loads/components/mars-items/mars-items.module";
import { LoadsFiltersModule } from "./shared/loads-filters/loads-filters.module";
import { LoadsFiltersComponent } from "./shared/loads-filters/loads-filters.component";
import { PreloadsFiltersModule } from "./shared/preloads-filters/preloads-filters.module";
import { PreloadsFiltersComponent } from './shared/preloads-filters/preloads-filters.component';

import { NgxLoadingModule, ngxLoadingAnimationTypes } from "ngx-loading";
import { modals } from "./modals";
import { CreateFactoringCompanyModule } from "./components/catalogos/factoring-companies/components/create-factoring-company/create-factoring-company.module";

@NgModule({
  imports: [
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule.forRoot(AppRoutes),
    NgbModule.forRoot(),
    HttpModule,
    SidebarModule,
    NavbarModule,
    FooterModule,
    HttpClientModule,
    AngularTokenModule.forRoot({
      apiBase: 'https://api.tmsarise.com',
      apiPath: null,
      signInPath: "auth/login",
      signInRedirect: "/dashboard",
      signInStoredUrlStorageKey: "token_bba",
      signOutPath: "/",
      loginField: "usuario",
    }),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyAgSoIu9Zn8QIZSLqgfok3wXR4TRcdPppY",
    }),
    NgxPermissionsModule.forRoot(),
    ToastrModule.forRoot(),
    NgxDatatableModule,
    NgxSmartModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    GooglePlaceModule,
    NgSelectModule,
    FileUploadModule,
    AttachFilesModalModule,
    NewLogModalModule,
    LoadCarrierMsgModule,
    TextlocateModule,
    CalendarModalModule,
    CreateIncomingLoadModalModule,
    ViewIncomingLoadModalModule,
    ConfirmationModalModule,
    CreateRouteMxModule,
    CreateSaleModule,
    LoadTrailersModule,
    MarsItemsModule,
    LoadsFiltersModule,
    PreloadsFiltersModule,
    CreateFactoringCompanyModule,
    DuplicateLoadModalModule,
    ActivityLogDetailModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.wanderingCubes,
      backdropBorderRadius: "3px",
    }),
    ...modals,
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    LoginComponent,
  ],
  bootstrap: [AppComponent],
  providers: [
    Globals,
    AuthGuard,
    GeocodeService,
    CurrencyPipe,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  entryComponents: [
    LoadCarrierMsgComponent,
    TextlocateComponent,
    TextlocateHistoryComponent,
    TextlocateLocateComponent,
    CalendarModalComponent,
    CreateIncomingLoadModalComponent,
    ViewIncomingLoadModalComponent,
    ConfirmationModalComponent,
    CreateRouteMxComponent,
    CreateSaleComponent,
    LoadsFiltersComponent,
    PreloadsFiltersComponent,
    DuplicateLoadModalComponent,
    ActivityLogDetailComponent
  ],
})
export class AppModule {}
