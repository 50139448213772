import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PdfModalComponent } from "./pdf-modal.component";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";

@NgModule({
  declarations: [PdfModalComponent],
  imports: [CommonModule, NgxSmartModalModule, NgxExtendedPdfViewerModule],
  exports: [PdfModalComponent],
  entryComponents: [PdfModalComponent],
})
export class PdfModalModule {}
