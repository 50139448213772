import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MessagesService } from "app/services/messages.service";
import { ToastrManager } from "ng6-toastr-notifications";
import { NgxSmartModalService } from "ngx-smart-modal";
import { BehaviorSubject, Subject } from "rxjs";
import { finalize, takeUntil } from "rxjs/operators";

@Component({
  selector: "load-carrier-msg",
  templateUrl: "./load-carrier-msg.component.html",
  styleUrls: ["./load-carrier-msg.component.css"],
})
export class LoadCarrierMsgComponent implements OnInit, OnDestroy {
  private MODAL_NAME = "MESSAGES_MODAL";
  private onDestroy$ = new Subject<void>();
  public section$ = new BehaviorSubject<"SEND" | "HISTORY">("SEND");
  public load_: any;
  public pingHistory: any[];
  public msgForm: FormGroup;

  public selectedPing: any;

  public sections = ["SEND", "HISTORY"];

  public requesting: boolean;
  public loading: boolean;

  //Recieves the load id to get the info
  @Input() load(id: number) {
    this.getLoad(id);
  }

  constructor(
    private _message: MessagesService,
    private fb: FormBuilder,
    private modal: NgxSmartModalService,
    private _toast: ToastrManager
  ) {}

  get disabled() {
    return this.msgForm.invalid || this.requesting;
  }

  ngOnInit() {
    this.msgForm = this.fb.group({
      loadCarrier: [null, [Validators.required]],
      message: [null, [Validators.required]],
    });
    this.getModalData();
    this.onSectionChange();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onSectionChange() {
    this.section$.pipe(takeUntil(this.onDestroy$)).subscribe((value) => {
      if (value === "HISTORY")
        this.getPingHistory(this.modal.get(this.MODAL_NAME).getData()["load"]);
    });
  }

  getModalData() {
    this.getLoad(this.modal.get(this.MODAL_NAME).getData()["load"]);
    this.modal
      .get(this.MODAL_NAME)
      .onDataAdded.pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => {
        this.getLoad(data["load"]);
      });
  }

  openPing(ping: any) {
    this.selectedPing = ping;
    this.modal.open("loadMapModal");
    this.modal
      .get("loadMapModal")
      .onClose.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => (this.selectedPing = null));
  }

  //Req
  getLoad(id: number) {
    this._message
      .getLoad(id.toString())
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => {
        this.load_ = value;
      });
  }

  getPingHistory(load: string) {
    this.pingHistory = [];
    this.loading = true;
    this._message
      .getPingHistory(load)
      .pipe(
        takeUntil(this.onDestroy$),
        finalize(() => (this.loading = false))
      )
      .subscribe(
        (pings: any) => {
          this.pingHistory = pings;
        },
        (err) => this._toast.errorToastr("Error trying to get history.")
      );
  }

  sendMessage() {
    if (this.msgForm.invalid) return;
    const data = this.msgForm.value;
    this.requesting = true;
    this._message
      .sendMessage(data)
      .pipe(
        takeUntil(this.onDestroy$),
        finalize(() => (this.requesting = false))
      )
      .subscribe(
        (res: any) => {
          this._toast.successToastr(`Message sent to ${res.to}.`);
        },
        (err) => this._toast.errorToastr("Error trying to send message")
      );
  }
}
