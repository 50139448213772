import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { CustomersService } from "app/services/customers.service";
import { DispatchersService } from "app/services/dispatchers.service";
import { PreloadService } from "app/services/preload.service";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { NgxSmartModalComponent, NgxSmartModalService } from "ngx-smart-modal";
import { concat, Observable, of, Subject } from "rxjs";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  switchMap,
  takeUntil,
  tap,
} from "rxjs/operators";
import * as moment from "moment";
import { ToastrManager } from "ng6-toastr-notifications";
import { WorkOrdersService } from "app/services/work-orders.service";
import { Globals } from "app/globals";
import { CarriersService } from "app/services/carriers.service";
import { OperatorsService } from "app/services/operators.service";
import { LoadsService } from "app/services/loads.service";
import { FiltersService } from "app/services/filters.service";
import * as _ from "lodash";
import { UsuariosService } from "app/services/usuarios.service";

export const LOADS_FILTERS_MODAL = "LOADS_FILTERS_MODAL";

@Component({
  selector: "app-loads-filters",
  templateUrl: "./loads-filters.component.html",
  styleUrls: ["./loads-filters.component.css"],
})
export class LoadsFiltersComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private fb: FormBuilder,
    private _customers: CustomersService,
    private _carriers: CarriersService,
    private _dispatchers: DispatchersService,
    private _users: UsuariosService,
    private _operators: OperatorsService,
    private _preloads: PreloadService,
    private _toastr: ToastrManager,
    private _workOrders: WorkOrdersService,
    private globals: Globals,
    public _loads: LoadsService,
    private _filters: FiltersService,
    private _modal: NgxSmartModalService
  ) {}

  private readonly onDestroy$ = new Subject<any>();

  @Output("getValues") private getValues = new EventEmitter<any>();
  @ViewChild("filtersModal") public filtersModal: NgxSmartModalComponent;

  public readonly status = [
    { value: "activo", label: "Active" },
    { value: null, label: "All" },
  ];

  public shippersCountries = this._loads.getShippersCountries();
  public consigneesCountries = this._loads.getConsigneesCountries();

  public filtersForm: FormGroup;

  private toastOpts = { maxShown: 1, animate: "slideFromBottom" };
  public readonly bsConfig: Partial<BsDatepickerConfig> = {
    containerClass: "theme-default",
    dateInputFormat: "DD/MM/YYYY",
  };

  private readonly workOrders$ = of({}).pipe(
    switchMap(() => this._workOrders.getAll()),
    takeUntil(this.onDestroy$)
  );
  public readonly models = {
    carriers: {
      items$: new Observable<any[]>(),
      loading: false,
      input$: new Subject<string>(),
      req$: (keyword) => this._carriers.searchCarriers(keyword),
      initValue: this._filters.extraData["carriers"],
    },
    consignees: {
      items$: new Observable<any[]>(),
      loading: false,
      input$: new Subject<string>(),
      req$: (keyword) =>
        this._preloads
          .searchConsignees(keyword, "accounting")
          .pipe(map((data) => this.mapDestOrigin(data))),
      initValue: this._filters.extraData["destinationConsignees"]
        ? this._filters.extraData["destinationConsignees"]
        : null,
    },
    consigneesCities: {
      items$: new Observable<any[]>(),
      loading: false,
      input$: new Subject<string>(),
      req$: (keyword) => this._loads.searchConsigneesCities(keyword),
      initValue: this._filters.extraData["destinationCity"],
    },
    customers: {
      items$: new Observable<any[]>(),
      loading: false,
      input$: new Subject<string>(),
      req$: (keyword) => this._customers.search(keyword),
      initValue: this._filters.extraData["customers"],
    },
    dispatchers: {
      items$: new Observable<any[]>(),
      loading: false,
      input$: new Subject<string>(),
      req$: (keyword) => this._dispatchers.search(keyword),
      initValue: this._filters.extraData["dispatchers"],
    },
    managers: {
      items$: new Observable<any[]>(),
      loading: false,
      input$: new Subject<string>(),
      req$: (keyword) => this._users.searchManagersUsers(keyword),
      initValue: this._filters.extraData["manager"]
        ? this._filters.extraData["manager"]
        : null
    },
    accounting: {
      items$: new Observable<any[]>(),
      loading: false,
      input$: new Subject<string>(),
      req$: (keyword) => this._users.searchAccountingUsers(keyword),
      initValue: this._filters.extraData["accounting"] ? this._filters.extraData["accounting"] : null,
    },
    operators: {
      items$: new Observable<any[]>(),
      loading: false,
      input$: new Subject<string>(),
      req$: (keyword) => this._operators.search(keyword),
      initValue: this._filters.extraData["operator"]
        ? this._filters.extraData["operator"]
        : null,
    },
    shippers: {
      items$: new Observable<any[]>(),
      loading: false,
      input$: new Subject<string>(),
      req$: (keyword) =>
        this._preloads
          .searchShippers(keyword, "accounting")
          .pipe(map((data) => this.mapDestOrigin(data))),
      initValue: this._filters.extraData["originShippers"]
        ? this._filters.extraData["originShippers"]
        : null,
    },
    shippersCities: {
      items$: new Observable<any[]>(),
      loading: false,
      input$: new Subject<string>(),
      req$: (keyword) => this._loads.searchShippersCities(keyword),
      initValue: this._filters.extraData["originCity"],
    },
  };

  //On Time
  public workOrders = [];
  public readonly loadStatus = [...this.globals.loadStatus, "Not Invoiced"];
  public readonly equipmentTypes = this.globals.equipamentTypes;

  ngOnInit(): void {
    this.filtersForm = this.fb.group({
      date: this.fb.group({ from: [null], to: [null] }),
      invoiceDate: this.fb.group({ from: [null], to: [null] }),
      shipDate: this.fb.group({ from: [null], to: [null] }),
      deliveryDate: this.fb.group({ from: [null], to: [null] }),
      loadNumber: [null],
      invoiceNumber: [null],
      poNumber: [null],
      workOrder: [null],
      workOrderValue: [null],
      equipmentType: [null],
      status: [null],
      woRef: [null],
      commodity: [null],
      loadStatus: [null],
      trailerNumber: [null],

      operator: [null],
      manager: [null],
      customers: [null],
      carriers: [null],
      dispatchers: [null],
      accounting: [null],

      originShippers: [null],
      originCity: [null],
      originCountry: [null],

      destinationConsignees: [null],
      destinationCity: [null],
      destinationCountry: [null],
    });
    this.initFilters();
    this.loadData();
    this.initListeners();
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.unsubscribe();
  }

  //Filters
  private initFilters() {
    if (!this._filters.filters) return;
    this.filtersForm.patchValue(this._filters.filters);
  }

  //Req
  public loadData() {
    this.getWorkOrders();
  }

  public getWorkOrders() {
    this.workOrders$.subscribe((res: any) => (this.workOrders = res));
  }

  //Listeners
  public initListeners() {
    this.initSearchers();
  }

  public initSearchers() {
    Object.keys(this.models).forEach((key) => {
      this.models[key].items$ = concat(
        of(this.models[key].initValue || []),
        this.models[key].input$.pipe(
          distinctUntilChanged(),
          debounceTime(500),
          tap(() => (this.models[key].loading = true)),
          switchMap((term) =>
            this.models[key].req$(term).pipe(
              catchError(() => of([])),
              finalize(() => (this.models[key].loading = false))
            )
          )
        )
      );
    });
  }

  public onSelectChange(type: string, event: any) {
    this._filters.extraData = { ...this._filters.extraData, [type]: event };
  }

  //Values
  public sendValues() {
    if (this.filtersForm.invalid)
      return this._toastr.errorToastr("Invalid Form", "", this.toastOpts);
    //Send
    this._filters.filters = this.filtersForm.value;
    this.getValues.emit(this.filtersForm.value);
    this._modal.get(LOADS_FILTERS_MODAL).close();
  }
  //Validators

  //Maps
  private mapDestOrigin(data: any[]) {
    return data.map((e) => {
      e.cityFormat = `${e.city ? e.city : "?"}, ${e.state ? e.state : "?"}`;
      e.cityFormatLbl = `, ${e.city ? e.city : "?"}, ${
        e.state ? e.state : "?"
      }`;
      return e;
    });
  }

  //Utils
  public resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      status: "activo",
    });
    this._filters.extraData = {};
  }

  filterCitiesFn(term: string, item: any) {
    term = term.toLowerCase();
    return item.city.toLowerCase().indexOf(term) != -1;
  }
  filterShipperFn(term: string, item: any) {
    term = term.toLowerCase();
    return (
      item.name.toLowerCase().indexOf(term) != -1 ||
      item.city.toLowerCase().indexOf(term) != -1
    );
  }
  filterConsigneeFn(term: string, item: any) {
    term = term.toLowerCase();
    return (
      item.name.toLowerCase().indexOf(term) != -1 ||
      item.city.toLowerCase().indexOf(term) != -1
    );
  }
}
