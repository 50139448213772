import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NavbarComponent } from "./navbar.component";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { ReactiveFormsModule } from "@angular/forms";
import { CalendarModalModule } from "../modals/calendar-modal/calendar-modal.module";

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    NgxSmartModalModule,
    ReactiveFormsModule,
    CalendarModalModule,
  ],
  declarations: [NavbarComponent],
  exports: [NavbarComponent],
})
export class NavbarModule {}
