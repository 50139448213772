import { Routes } from "@angular/router";

import { AdminLayoutComponent } from "./layouts/admin/admin-layout.component";
import { LoginComponent } from "./components/login/login.component";

export const AppRoutes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "formats",
    loadChildren: "./public/formats/formats.module#FormatsModule",
  },
  {
    path: "",
    component: AdminLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: "./components/dashboard/dashboard.module#DashboardModule",
      },
      {
        path: "accounting",
        loadChildren:
          "./components/accounting/accounting.module#AccountingModule",
      },
      {
        path: "catalogos",
        loadChildren: "./components/catalogos/catalogos.module#CatalogosModule",
      },
      {
        path: "reportes",
        loadChildren: "./components/reportes/reportes.module#ReportesModule",
      },
      {
        path: "pre-loads",
        loadChildren: "./components/pre-loads/preloads.module#PreloadsModule",
      },
      {
        path: "loads",
        loadChildren: "./components/loads/loads.module#LoadsModule",
      },
      {
        path: "config",
        loadChildren: "./components/config/config.module#ConfigModule",
      },
      {
        path: "commissions",
        loadChildren:
          "./components/commissions/commissions.module#CommissionsModule",
      },
      {
        path: "sales",
        loadChildren:
          "./components/sales/sales.module#SalesModule",
      },
      {
        path: "pricing",
        loadChildren:
          "./components/pricing/pricing.module#PricingModule",
      },
    ],
  },
];
