import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Globals } from './../globals';
import { Router } from '@angular/router';
import { of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OperatorsService {

  constructor(private http: HttpClient, private globals: Globals, private router: Router) {
  }

  getAll() {
    // return this.http.get(`${this.globals.apiUrl}/operators?status=activo`).pipe(catchError(this.handleError));
    return this.http.get(`${this.globals.apiUrl}/operators/getAll`).pipe(catchError(this.handleError));
  }

  add(operator) {
    return this.http.post(`${this.globals.apiUrl}/operators/add`, {
      operator: operator
    }).pipe(catchError(this.handleError));
  }

  edit(operator) {
    return this.http.post(`${this.globals.apiUrl}/operators/edit`, {
      operator: operator
    }).pipe(catchError(this.handleError));
  }

  delete(id) {
    return this.http.post(`${this.globals.apiUrl}/operators/delete`, {
      id: id
    }).pipe(catchError(this.handleError));
  }
  search(search: string) {
    if (search) {
      return this.http.get<any>(`${this.globals.apiUrl}/usuarios/searchOperators`, {
        params: { search: search }
      }).pipe(
        map((res: any) => {
          return res.operators.map((operator: any) => {
            operator.fullName = `${operator.name} ${operator.lastName}`;
            return operator;
          });
        }),
        catchError(this.handleError)
      );
    } else {
      return of([]);
    }
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(`Backend returned error ${error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  };
}
