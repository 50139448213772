import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Globals } from "app/globals";

@Injectable({
  providedIn: "root",
})
export class FilesService {
  constructor(private http: HttpClient, private globals: Globals) {}

  public saveDownloadedFile(data, fileName) {
    let a: any = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    let blob = new Blob([data], { type: "octet/stream" }),
      url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  public openFile(data) {
    const file = new Blob([data], { type: data.type });
    const url = URL.createObjectURL(file);
    window.open(url);
  }
}
