import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  CREATE_FACTORING_COMPANY_MODAL,
  CreateFactoringCompanyComponent,
} from "app/components/catalogos/factoring-companies/components/create-factoring-company/create-factoring-company.component";
import { Globals } from "app/globals";
import { CarriersService } from "app/services/carriers.service";
import { FactoringCompaniesService } from "app/services/factoring-companies.service";
import { UsuariosService } from "app/services/usuarios.service";
import { ToastrManager } from "ng6-toastr-notifications";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Subject } from "rxjs";
import { finalize, takeUntil } from "rxjs/operators";

export const CREATE_CARRIER_MODAL = "CREATE_CARRIER_MODAL";

@Component({
  selector: "app-create-carrier",
  templateUrl: "./create-carrier.component.html",
  styleUrls: ["./create-carrier.component.css"],
})
export class CreateCarrierComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<void>();

  public bsConfig: Partial<BsDatepickerConfig> = {
    containerClass: "theme-default"
  };

  //Status
  public loading: boolean;
  public requesting: boolean;

  //Data
  public data: any;
  public factoryCompanies: any[] = [];
  public dispatchers: any[] = [];
  public paymentMethods = this.globals.paymentMethods;
  public equipmentTypes = this.globals.equipamentTypes;
  public countries = ["MX", "CA"];

  //Forms
  public form: FormGroup;

  constructor(
    private _carriers: CarriersService,
    private fb: FormBuilder,
    private _modal: NgxSmartModalService,
    private _toast: ToastrManager,
    private _factComps: FactoringCompaniesService,
    private globals: Globals,
    private _usuarios: UsuariosService
  ) {}

  get disabled() {
    return this.form.invalid || this.requesting || this.loading;
  }

  ngOnInit() {
    this.form = this.fb.group({
      name: [null, Validators.required],
      address: [null, Validators.required],
      email: [null, Validators.required],
      contact: [null],
      telephone: [null, Validators.required],
      tax_id: [null, Validators.required],
      mc_number: [null, Validators.required],
      dot_number: [null],
      factoryCompany: [null],
      paymentMethod: [null],
      dispatcher: [null, Validators.required],
      insurance_liability_company: [null, Validators.required],
      insurance_policy: [null, Validators.required],
      insurance_expiration_date: [null, Validators.required],
      insurance_telephone: [null, Validators.required],
      insurance_contact: [null],
      insurance_liability: [null, Validators.required],
      accounting_emails: [null],
      equipmentOffer: [null],
      doorToDoor: [null],
      inBlackList: [null],
      blackListReason: [null],
      complete: [true],
    });
    this.getData();
    this.getFactoryCompanies();
    this.getDispatchers();
    this.initListeners();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  initListeners() {
    this.onFactoryCompanyChanges();
  }

  onFactoryCompanyChanges() {
    this.form.controls["factoryCompany"].valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => {
        if (!value) return;
        const factory = this.factoryCompanies.find(({ id }) => id == value);
        if (factory)
          this.form.controls["paymentMethod"].patchValue(
            factory.paymentMethod || null
          );
      });
  }

  getData() {
    const { id } = this._modal.getModalData(CREATE_CARRIER_MODAL);
    if (!id) return;
    this.loading = true;
    this._carriers
      .getById(id)
      .pipe(
        finalize(() => (this.loading = false)),
        takeUntil(this.onDestroy$)
      )
      .subscribe(
        (data) => {
          this.data = data;
          const {
            insurance_expiration_date,
            dispatcher,
            factoryCompany,
            paymentMethod,
          } = data;
          this.form.patchValue({
            ...data,
            insurance_expiration_date: insurance_expiration_date
              ? new Date(insurance_expiration_date)
              : null,
            dispatcher: dispatcher ? dispatcher.id : null,
            factoryCompany: factoryCompany ? factoryCompany.id : null,
            paymentMethod: !!paymentMethod ? paymentMethod : null,
          });
        },
        (error) =>
          this._toast.errorToastr("Error getting carrier.", "Error", {
            maxShown: 1,
            animate: "slideFromBottom",
          })
      );
  }

  getFactoryCompanies() {
    this._factComps
      .getAll()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (data: any) => (this.factoryCompanies = data),
        (err) => {
          this._toast.errorToastr(
            "Error getting factory companies list, verify your internet connection.",
            "Error",
            { maxShown: 1, animate: "slideFromBottom" }
          );
        }
      );
  }

  getDispatchers() {
    this._usuarios
      .getDispatchers()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (data: any) => (this.dispatchers = data),
        (err) => {
          this._toast.errorToastr(
            "Error getting dispatcher list, verify your internet connection.",
            "Error",
            { maxShown: 1, animate: "slideFromBottom" }
          );
        }
      );
  }

  onSubmit() {
    if (this.form.invalid)
      return this._toast.errorToastr("Invalid Form.", "Error", {
        maxShown: 1,
        animate: "slideFromBottom",
      });
    if (this.data) this.update();
    else this.create();
  }

  formatData() {
    const value = this.form.getRawValue();
    if (value.email && typeof value.email == "string")
      value.email = value.email.split(",").map((email) => email.trim());
    if (value.accounting_emails && typeof value.accounting_emails == "string")
      value.accounting_emails = value.accounting_emails
        .split(",")
        .map((email) => email.trim());
    return value;
  }

  openCreateFactoringCompany() {
    this._modal
      .create(CREATE_FACTORING_COMPANY_MODAL, CreateFactoringCompanyComponent, {
        dismissable: false,
        closable: false,
      })
      .removeData()
      .setData({})
      .open()
      .onAnyCloseEventFinished.subscribe(({ _data }) => {
        if (_data && _data.data) {
          this.factoryCompanies = [...this.factoryCompanies, _data.data];
          this.form.controls["factoryCompany"].setValue(_data.data.id);
        }
        this._modal.removeModal(CREATE_FACTORING_COMPANY_MODAL);
      });
  }

  create() {
    this.requesting = true;
    this._carriers
      .create(this.formatData())
      .pipe(
        finalize(() => (this.requesting = false)),
        takeUntil(this.onDestroy$)
      )
      .subscribe(
        (data) => {
          this._toast.successToastr("Carrier added successfully.", "", {
            maxShown: 1,
            animate: "slideFromBottom",
          });
          this._modal
            .get(CREATE_CARRIER_MODAL)
            .removeData()
            .setData({ data })
            .close();
        },
        (error) =>
          this._toast.errorToastr("Error adding carrier, try again.", "", {
            maxShown: 1,
            animate: "slideFromBottom",
          })
      );
  }

  update() {
    this.requesting = true;
    this._carriers
      .update(this.data.id, this.formatData())
      .pipe(
        finalize(() => (this.requesting = false)),
        takeUntil(this.onDestroy$)
      )
      .subscribe(
        (data) => {
          this._toast.successToastr("Carrier edited successfully.", "", {
            maxShown: 1,
            animate: "slideFromBottom",
          });
          this._modal
            .get(CREATE_CARRIER_MODAL)
            .removeData()
            .setData({ data })
            .close();
        },
        (error) =>
          this._toast.errorToastr("Error editing carrier, try again.", "", {
            maxShown: 1,
            animate: "slideFromBottom",
          })
      );
  }

  close() {
    this._modal.get(CREATE_CARRIER_MODAL).removeData().close();
  }
}
