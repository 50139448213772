import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Globals } from "app/globals";

@Injectable({
  providedIn: "root",
})
export class IncomingLoadsService {
  constructor(private http: HttpClient, private globals: Globals) {}

  readonly API_ROUTE = `${this.globals.apiUrl}/incomingLoads`;

  add(data: any) {
    return this.http.post<any>(this.API_ROUTE, data);
  }

  update(id: number, data: any) {
    return this.http.patch<any>(`${this.API_ROUTE}/${id}`, data);
  }

  get(filters: { from: string; to: string }) {
    return this.http.get<[any[], number]>(this.API_ROUTE, { params: filters });
  }

  getById(id: number) {
    return this.http.get<any>(`${this.API_ROUTE}/${id}`);
  }

  delete(id: number) {
    return this.http.delete<any>(`${this.API_ROUTE}/${id}`);
  }
}
