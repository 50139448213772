import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Globals } from "app/globals";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class TextLocateService {
  constructor(private http: HttpClient, private globals: Globals) {}

  private PUBLIC_KEY = "fd44e8ae-4268-4330-bcb8-b62955c18a23";

  getLoad(load: string) {
    return this.http.get(`${this.globals.apiUrl}/text-locate/getLoad`, {
      params: { load },
    });
  }

  getLoads(load: string, page, limit) {
    return this.http.get<{ loads: any[]; count: number }>(
      `${this.globals.apiUrl}/text-locate/getLoads`,
      {
        headers: { TextLocate: this.PUBLIC_KEY },
        params: { load, page, limit },
      }
    );
  }

  sendMessage(data) {
    return this.http.post(`${this.globals.apiUrl}/text-locate/send`, data, {
      headers: { TextLocate: this.PUBLIC_KEY },
    });
  }

  getPings(load: string) {
    return this.http.get(
      `${this.globals.apiUrl}/text-locate/getPings/${load}`,
      {
        headers: { TextLocate: this.PUBLIC_KEY },
      }
    );
  }

  getHistory(textlocateId: string) {
    return this.http
      .get<any[]>(
        `${this.globals.apiUrl}/text-locate/getHistory/${textlocateId}`,
        {
          headers: { TextLocate: this.PUBLIC_KEY },
        }
      )
      .pipe(
        map((history) => history.filter(({ responseTime }) => responseTime))
      );
  }
}
