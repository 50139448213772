import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Globals } from "app/globals";

@Injectable({
  providedIn: "root",
})
export class SalesService {
  constructor(private http: HttpClient, private globals: Globals) {}

  private readonly ROUTE = `${this.globals.apiUrl}/sales`;

  create(data: any) {
    return this.http.post(`${this.ROUTE}`, data);
  }

  getAll(opts?: {
    page?: number;
    limit?: number;
    salesperson?: number;
    operator?: number;
    filters?: string;
  }) {
    return this.http.get<[number, any[]]>(`${this.ROUTE}`, {
      params: <any>opts,
    });
  }

  getById(id: number) {
    return this.http.get<any>(`${this.ROUTE}/${id}`);
  }

  update(id: number, data: any) {
    return this.http.patch<any>(`${this.ROUTE}/${id}`, data);
  }

  getFile(id: number) {
    return this.http.get(`${this.ROUTE}/files/${id}`, {
      responseType: "arraybuffer",
    });
  }
}
