import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CreateRouteMxComponent } from "./create-route-mx.component";
import { ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { NgxLoadingModule, ngxLoadingAnimationTypes } from "ngx-loading";
import { QuillModule } from "ngx-quill";
import { NgxSmartModalModule } from "ngx-smart-modal";

@NgModule({
  declarations: [CreateRouteMxComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxSmartModalModule,
    QuillModule.forRoot(),
    NgSelectModule,
    NgxDatatableModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.wanderingCubes,
      backdropBorderRadius: "3px",
    }),
  ],
})
export class CreateRouteMxModule {}
