import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { TableColumn } from "@swimlane/ngx-datatable";
import { Globals } from "app/globals";
import { LoadsService } from "app/services/loads.service";
import { UsuariosService } from "app/services/usuarios.service";
import { ToastrManager } from "ng6-toastr-notifications";
import { Address } from "ngx-google-places-autocomplete/objects/address";
import { NgxSmartModalService } from "ngx-smart-modal";
import { of, Subject } from "rxjs";
import { finalize, switchMap, takeUntil, tap } from "rxjs/operators";
import * as moment from "moment";

@Component({
  selector: "app-new-log-modal",
  templateUrl: "./new-log-modal.component.html",
  styleUrls: ["./new-log-modal.component.css"],
})
export class NewLogModalComponent implements OnInit, OnDestroy {
  public readonly MODAL_ID = "NEW_LOG_MODAL";
  private readonly onDestroy$ = new Subject<void>();
  @ViewChild("dateTemp") dateTemp: ElementRef<HTMLTemplateElement>;
  @ViewChild("statusTemp") statusTemp: ElementRef<HTMLTemplateElement>;
  public load: number;
  public loadNumber: string;
  public completed: boolean;
  public logs: any[];
  public logForm: FormGroup;
  public loading = false;
  public requesting = false;
  public loadStatus = this.globals.loadStatus.filter(
    (status) => status !== "Invoiced" && status !== "Pending"
  );
  public address: any;
  public columns: TableColumn[];
  public pagination = {
    limit: 10,
    options: [50, 75, 100],
  };

  //Observables
  private readonly getLogs$ = () =>
    of({}).pipe(
      tap(() => (this.loading = true)),
      switchMap(() => this._loads.getLogs(this.load)),
      finalize(() => (this.loading = false)),
      takeUntil(this.onDestroy$)
    );

  private readonly createLog$ = () =>
    of({}).pipe(
      tap(() => (this.requesting = true)),
      switchMap(() => {
        if (this.logForm.invalid) throw "Error in form.";
        const { permisos, id: user } = this._usuarios.getCurrentUser();
        const hasPerm = permisos.includes("LOGS");
        if (this.completed && !hasPerm)
          throw "This load has already been completed.";
        let { googleLocation, ...log } = this.logForm.value;
        if (log.location && googleLocation) {
          if (!this.address) {
            throw "You must search and select the consignee address from google dropdown";
          }
          if (!Object.keys(this.address).length) {
            throw "You must search and select the consignee address from google dropdown";
          }
          log = {
            ...log,
            location: this.address.formatted_address,
            locationLat: this.address.geometry.location.lat(),
            locationLng: this.address.geometry.location.lng(),
          };
        }
        log = { ...log, load: this.load, user, date: moment().toDate() };
        return this._loads.addLog(JSON.stringify(log));
      }),
      finalize(() => (this.requesting = false)),
      takeUntil(this.onDestroy$)
    );

  constructor(
    private fb: FormBuilder,
    private _loads: LoadsService,
    private _usuarios: UsuariosService,
    private _modal: NgxSmartModalService,
    private _toast: ToastrManager,
    private globals: Globals
  ) {}

  get disabled() {
    return this.logForm.invalid || this.requesting;
  }

  get googleLocation() {
    return this.logForm.controls["googleLocation"].value;
  }

  ngOnInit() {
    this.initForms();
    this.initTables();
    this.initListeners();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  initForms() {
    this.logForm = this.fb.group({
      loadStatus: [null, Validators.required],
      log: [""],
      location: [null],
      locationLat: [""],
      locationLng: [""],
      googleLocation: [true],
    });
  }

  initTables() {
    this.columns = [
      { name: "Date", cellTemplate: this.dateTemp },
      { name: "Status", cellTemplate: this.statusTemp },
      { name: "Notes", prop: "log" },
      { name: "User", prop: "user.name" },
    ];
  }

  initListeners() {
    this.onDataChange();
    this.onGoogleLocationChanges();
  }

  onDataChange() {
    const modal = this._modal.get(this.MODAL_ID);
    this.initData();
    modal.onDataAdded.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.initData();
    });
    modal.onDataRemoved.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.resetData();
    });
  }

  initData() {
    const modal = this._modal.get(this.MODAL_ID);
    const { load, loadNumber, completed } = modal.getData();
    if (!load) return;
    this.loadNumber = loadNumber || load;
    this.load = load;
    this.completed = completed;
    this.getLogs();
  }

  resetData() {
    this.loadNumber = null;
    this.load = null;
    this.completed = null;
  }

  onGoogleLocationChanges() {
    this.logForm.controls["googleLocation"].valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.resetAddress());
  }

  //Req
  getLogs() {
    this.getLogs$().subscribe(
      (logs: any[]) => {
        this.logs = logs.map((log) => ({
          ...log,
          loadStatusClass: this.globals.loadStatusClass[log.loadStatus],
        }));
      },
      (error) => this._toast.errorToastr(error)
    );
  }

  createLog() {
    this.createLog$().subscribe(
      () => {
        this._toast.successToastr("Log added successfully.");
        this._modal.get(this.MODAL_ID).removeData();
        this._modal.get(this.MODAL_ID).setData({ created: true });
        this.closeModal();
      },
      (error) => this._toast.errorToastr(error)
    );
  }

  //Address
  addressChange(address: Address) {
    this.address = address;
    this.logForm.patchValue({ location: address.formatted_address });
  }

  resetAddress() {
    this.address = null;
    this.logForm.patchValue({ locationLat: "", locationLng: "" });
  }

  //Table
  onLimitChange(value) {
    this.pagination.limit = parseInt(value);
  }

  //Modal
  closeModal() {
    this._modal.get(this.MODAL_ID).close();
  }

  //Utils
  invalidControl(control: AbstractControl) {
    return control.invalid && control.touched;
  }
}
