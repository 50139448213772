import { Component, OnDestroy, OnInit } from "@angular/core";
import { TextLocateService } from "app/services/text-locate.service";
import { NgxSmartModalComponent, NgxSmartModalService } from "ngx-smart-modal";
import { Subject, of } from "rxjs";
import { finalize, switchMap, takeUntil, tap } from "rxjs/operators";

@Component({
  selector: "app-textlocate-history",
  templateUrl: "./textlocate-history.component.html",
  styleUrls: ["./textlocate-history.component.css"],
})
export class TextlocateHistoryComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();
  private MODAL_ID = "TEXTLOCATE_HISTORY_MODAL";

  //Req
  private readonly data$ = of({}).pipe(
    tap(() => (this.loading = true)),
    switchMap(() => this._textLocate.getHistory(this.textlocateId.toString())),
    finalize(() => (this.loading = false)),
    takeUntil(this.onDestroy$)
  );

  //Status
  public pagination = {
    page: 0,
    limit: 0,
    count: 0,
    skip: function () {
      this.page * this.limit;
    },
    limitOptions: [20, 50, 100],
  };

  //Data
  public textlocateId: number;
  public carrier: string;
  public history: any[];
  public loading: boolean;
  public requesting: boolean;

  constructor(
    private _textLocate: TextLocateService,
    private _modal: NgxSmartModalService
  ) {}

  ngOnInit() {
    this.initData();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  initData() {
    const modal = this._modal.get(this.MODAL_ID);
    this.setData(modal);
    modal.onDataAdded
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.setData(this._modal.get(this.MODAL_ID)));
    modal.onDataRemoved
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.resetData());
    modal.onAnyCloseEvent
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.resetData());
  }

  setData(modal: NgxSmartModalComponent) {
    const { textlocateId, carrier } = modal.getData();
    this.textlocateId = textlocateId;
    this.carrier = carrier;
    this.getData();
  }

  resetData() {
    this.textlocateId = null;
    this.history = [];
  }

  getData() {
    this.data$.subscribe((history) => {
      this.history = history;
    });
  }
}
