import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ToastrModule } from "ng6-toastr-notifications";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { ngxLoadingAnimationTypes, NgxLoadingModule } from "ngx-loading";
import { NewLogModalComponent } from "./new-log-modal.component";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { NgSelectModule } from "@ng-select/ng-select";

@NgModule({
  declarations: [NewLogModalComponent],
  imports: [
    CommonModule,
    ToastrModule.forRoot(),
    NgxSmartModalModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.wanderingCubes,
      backdropBorderRadius: "3px",
    }),
    GooglePlaceModule,
    ReactiveFormsModule,
    FormsModule,
    NgxDatatableModule,
    NgSelectModule,
  ],
  exports: [NewLogModalComponent],
  entryComponents: [NewLogModalComponent],
})
export class NewLogModalModule {}
