import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from "angular-calendar";
import { NgxSmartModalService } from "ngx-smart-modal";
import {
  CREATE_INCOMING_LOAD_MODAL_ID,
  CreateIncomingLoadModalComponent,
} from "../create-incoming-load-modal/create-incoming-load-modal.component";
import { IncomingLoadsService } from "app/services/incoming-loads.service";
import { BehaviorSubject, Subject, of } from "rxjs";
import { finalize, switchMap, takeUntil, tap } from "rxjs/operators";
import * as moment from "moment";
import {
  VIEW_INCOMING_LOAD_MODAL_ID,
  ViewIncomingLoadModalComponent,
} from "../view-incoming-load-modal/view-incoming-load-modal.component";
import {
  CONFIRMATION_MODAL_ID,
  ConfirmationModalComponent,
  ConfirmationModalData,
} from "../confirmation-modal/confirmation-modal.component";
import { ToastrManager } from "ng6-toastr-notifications";
import { UsuariosService } from "app/services/usuarios.service";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";

@Component({
  selector: "app-calendar-modal",
  templateUrl: "./calendar-modal.component.html",
  styleUrls: ["./calendar-modal.component.css"],
})
export class CalendarModalComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<void>();
  private readonly clicked$ = new BehaviorSubject<any>(0);
  private readonly clickedHour$ = new BehaviorSubject<any>(0);
  public readonly refresh$ = new Subject<void>();

  private readonly data$ = of({}).pipe(
    tap(() => (this.loading = true)),
    switchMap(() => {
      return this._incomingLoads.get({
        from: moment(this.viewDate).utcOffset(0).startOf("week").toISOString(),
        to: moment(this.viewDate).utcOffset(0).endOf("week").toISOString(),
      });
    }),
    finalize(() => (this.loading = false)),
    takeUntil(this.onDestroy$)
  );

  private readonly delete$ = (id: number) =>
    of({}).pipe(
      tap(() => (this.requesting = true)),
      switchMap(() => {
        return this._incomingLoads.delete(id);
      }),
      finalize(() => (this.requesting = false)),
      takeUntil(this.onDestroy$)
    );

  private readonly update$ = (id: number, data: any) =>
    of({}).pipe(
      tap(() => (this.requesting = true)),
      switchMap(() => {
        return this._incomingLoads.update(id, data);
      }),
      finalize(() => (this.requesting = false)),
      takeUntil(this.onDestroy$)
    );

  private readonly create$ = (data: any) =>
    of({}).pipe(
      tap(() => (this.requesting = true)),
      switchMap(() => {
        return this._incomingLoads.add(data);
      }),
      finalize(() => (this.requesting = false)),
      takeUntil(this.onDestroy$)
    );

  public events: CalendarEvent[];
  public viewDate: Date;
  public view: CalendarView;
  public daysInWeek = 7;

  public dateClicked: Date;
  public viewClicked: CalendarEvent;

  public loading: boolean;
  public requesting: boolean;

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt text-dark"></i>',
      a11yLabel: "Edit",
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleClick("edit", event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-clone text-dark"></i>',
      a11yLabel: "Clone",
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleClick("clone", event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt text-dark"></i>',
      a11yLabel: "Delete",
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleClick("delete", event);
      },
    },
  ];

  constructor(
    private _modal: NgxSmartModalService,
    private _incomingLoads: IncomingLoadsService,
    private _toast: ToastrManager,
    private _usuarios: UsuariosService,
    private breakpointObserver: BreakpointObserver,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.view = CalendarView.Week;
    this.viewDate = new Date();
    this.initListeners();
    this.getData();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  initListeners() {
    this.dblClick();
    // this.responsive();
  }

  responsive() {
    const CALENDAR_RESPONSIVE = {
      small: {
        breakpoint: "(max-width: 576px)",
        daysInWeek: 2,
      },
      medium: {
        breakpoint: "(max-width: 768px)",
        daysInWeek: 3,
      },
      large: {
        breakpoint: "(max-width: 960px)",
        daysInWeek: 5,
      },
    };
    this.breakpointObserver
      .observe(
        Object.keys(CALENDAR_RESPONSIVE).map(
          (key) => CALENDAR_RESPONSIVE[key].breakpoint
        )
      )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((state: BreakpointState) => {
        const foundBreakpoint = Object.keys(CALENDAR_RESPONSIVE).find(
          (key) => !!state.breakpoints[CALENDAR_RESPONSIVE[key].breakpoint]
        );
        if (foundBreakpoint) {
          this.daysInWeek = CALENDAR_RESPONSIVE[foundBreakpoint].daysInWeek;
        } else {
          this.daysInWeek = 7;
        }
        this.cd.markForCheck();
      });
  }

  getData() {
    this.data$.subscribe(
      ([data]) => {
        this.events = data.map((d) => ({
          draggable: true,
          start: new Date(d.date),
          title: `${d.standBy ? "🟡" : d.covered ? "🟢" : "🔴"}${
            d.multiplePO ? "♻️" : ""
          } ${decodeURIComponent(d.title)}`,
          id: d.id,
          meta: d,
          color: {
            primary: d.operator ? d.operator.color : null,
            secondary: d.operator ? d.operator.color + "59" : null,
          },
          actions:
            d.user.id == this._usuarios.getCurrentUser().id
              ? this.actions
              : null,
        }));
      },
      (error) =>
        this._toast.errorToastr(
          "Error getting loads, verify your internet connection."
        )
    );
  }

  handleClick(type: "event" | "hour" | "edit" | "delete" | "clone", event) {
    if (type === "hour") this.hourClicked(event);
    if (type === "event") this.eventClicked(event);
    if (type === "edit") this.openCreateIncomingLoadModal(event);
    if (type === "delete") this.openConfirmationModal(event);
    if (type === "clone") this.openConfirmationCloneModal(event);
  }

  hourClicked({ date }: { date: Date }) {
    this.dateClicked = date;
    this.clickedHour$.next(this.clickedHour$.value + 1);
  }

  eventClicked({ event }: { event: CalendarEvent }) {
    this.viewClicked = event;
    this.clicked$.next(this.clicked$.value + 1);
  }

  eventDbClicked() {
    this.openViewIncomingLoadModal();
  }

  hourDbClicked() {
    this.openCreateIncomingLoadModal();
  }

  dblClick() {
    this.clicked$.subscribe((value) => {
      if (value != 0) setTimeout(() => this.clicked$.next(0), 500);
      if (value >= 2) this.eventDbClicked();
    });
    this.clickedHour$.subscribe((value) => {
      if (value != 0) setTimeout(() => this.clickedHour$.next(0), 500);
      if (value >= 2) this.hourDbClicked();
    });
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent) {
    event.start = newStart;
    event.end = newEnd;
    this.refresh$.next();
    this.update$(event.meta.id, { date: event.start }).subscribe(() =>
      this.getData()
    ),
      (error) => this._toast.errorToastr("Error updating load, try again.");
  }

  openCreateIncomingLoadModal(event?: any) {
    let data: any = {};
    if (event) data = { id: event.id, action: "edit" };
    else data = { date: this.dateClicked };

    this._modal.removeModal(CREATE_INCOMING_LOAD_MODAL_ID);
    this._modal
      .create(CREATE_INCOMING_LOAD_MODAL_ID, CreateIncomingLoadModalComponent)
      .setData(data)
      .open()
      .onAnyCloseEvent.pipe(takeUntil(this.onDestroy$))
      .subscribe(({ _data }) => {
        if (_data.updated) this.getData();
      });
  }
  openViewIncomingLoadModal() {
    this._modal.removeModal(VIEW_INCOMING_LOAD_MODAL_ID);
    this._modal
      .create(VIEW_INCOMING_LOAD_MODAL_ID, ViewIncomingLoadModalComponent)
      .setData({ id: this.viewClicked.id })
      .open();
  }

  openConfirmationModal(event: CalendarEvent) {
    this._modal
      .create(CONFIRMATION_MODAL_ID, ConfirmationModalComponent, {
        dismissable: false,
        closable: false,
      })
      .setData(<ConfirmationModalData>{
        title: "Delete scheduled load",
        message: "Are you sure to remove the scheduled load",
        strongMessage: event.meta.title,
        buttons: { confirm: { color: "danger", text: "DELETE" } },
      })
      .open()
      .onAnyCloseEvent.pipe(takeUntil(this.onDestroy$))
      .subscribe(({ _data: { confirmed } }) => {
        if (!confirmed) return;
        this.delete$(event.meta.id).subscribe(
          () => {
            this._toast.successToastr("Scheduled load deleted succefully.");
            this.getData();
          },
          (error) =>
            this._toast.errorToastr("Error removing scheduled load, try again.")
        );
      });
  }

  openConfirmationCloneModal(event: CalendarEvent) {
    this._modal
      .create(CONFIRMATION_MODAL_ID, ConfirmationModalComponent, {
        dismissable: false,
        closable: false,
      })
      .setData(<ConfirmationModalData>{
        title: "Clone scheduled load",
        message: "Are you sure to clone the scheduled load",
        strongMessage: event.meta.title,
        buttons: { confirm: { color: "info", text: "CLONE" } },
      })
      .open()
      .onAnyCloseEvent.pipe(takeUntil(this.onDestroy$))
      .subscribe(({ _data: { confirmed } }) => {
        if (!confirmed) return;
        const {
          id,
          user,
          operator,
          dispatcher,
          createdAt,
          updatedAt,
          ...data
        } = event.meta;
        const toCreate = {
          ...data,
          user: this._usuarios.getCurrentUser().id,
        };
        if (operator) toCreate.operator = operator.id;
        if (dispatcher) toCreate.dispatcher = dispatcher.id;
        this.create$(toCreate).subscribe(
          () => {
            this._toast.successToastr("Scheduled load Cloned succefully.");
            this.getData();
          },
          (error) =>
            this._toast.errorToastr("Error cloning scheduled load, try again.")
        );
      });
  }
}
