import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Globals } from "app/globals";
import { CustomersService } from "app/services/customers.service";
import { OperatorsService } from "app/services/operators.service";
import { SalesService } from "app/services/sales.service";
import { UsuariosService } from "app/services/usuarios.service";
import { FileUploader } from "ng2-file-upload";
import { ToastrManager } from "ng6-toastr-notifications";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { NgxSmartModalService } from "ngx-smart-modal";
import { BehaviorSubject, Subject, of } from "rxjs";
import {
  filter,
  finalize,
  map,
  skip,
  switchMap,
  takeUntil,
  tap,
} from "rxjs/operators";

export const CREATE_SALE_MODAL_ID = "CREATE_SALE";

@Component({
  selector: "app-create-sale",
  templateUrl: "./create-sale.component.html",
  styleUrls: ["./create-sale.component.css"],
})
export class CreateSaleComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<void>();

  private readonly uploader$ = new BehaviorSubject<any>(null);
  private readonly uploaderAtt$ = new BehaviorSubject<any>(null);

  //REQ
  public data$ = (id: number) =>
    of({}).pipe(
      tap(() => (this.loading = true)),
      switchMap(() => this._sales.getById(id)),
      finalize(() => (this.loading = false)),
      takeUntil(this.onDestroy$)
    );

  public create$ = (data: any) =>
    of({}).pipe(
      tap(() => (this.requesting = true)),
      switchMap(() => this._sales.create(data)),
      finalize(() => (this.requesting = false)),
      takeUntil(this.onDestroy$)
    );

  public update$ = (id: number, data: any) =>
    of({}).pipe(
      tap(() => (this.requesting = true)),
      switchMap(() => this._sales.update(id, data)),
      finalize(() => (this.requesting = false)),
      takeUntil(this.onDestroy$)
    );

  public file$ = (id: number) =>
    of({}).pipe(
      tap(() => (this.requesting = true)),
      switchMap(() => this._sales.getFile(id)),
      finalize(() => (this.requesting = false)),
      takeUntil(this.onDestroy$)
    );

  public customers$ = () =>
    of({}).pipe(
      switchMap(() => this._customers.getAllProspects()),
      takeUntil(this.onDestroy$)
    );

  public operators$ = () =>
    of({}).pipe(
      switchMap(() => this._operators.getAll()),
      takeUntil(this.onDestroy$)
    );

  public salespeople$ = () =>
    of({}).pipe(
      switchMap(() => this._usuarios.getAll()),
      map((usuarios: any) =>
        usuarios.filter(({ tipo }) => tipo == "salesperson")
      ),
      takeUntil(this.onDestroy$)
    );

  //CONFIG
  public bsConfig: Partial<BsDatepickerConfig> = {
    containerClass: "theme-default",
  };

  //STATUS
  public loading: boolean;
  public requesting: boolean;

  //DATA
  public data: any;
  public form: FormGroup;
  public status = this.globals.saleStatus;
  public customers: any[] = [];
  public salespeople: any[] = [];
  public operators: any[] = [];
  public salesperson: any;
  public operator: any;

  //FILES
  public uploader: FileUploader;
  public fileName: string;

  public uploaderAtt: FileUploader;
  public fileNameAtt: string;

  constructor(
    private _sales: SalesService,
    private _customers: CustomersService,
    private _usuarios: UsuariosService,
    private fb: FormBuilder,
    private _modal: NgxSmartModalService,
    private _toast: ToastrManager,
    private globals: Globals,
    private _operators: OperatorsService
  ) {}

  //GETTERS
  get disabled() {
    return this.requesting || this.form.invalid;
  }

  ngOnInit() {
    this.form = this.fb.group({
      date: [null, Validators.required],
      status: [null],
      customer: [null, Validators.required],
      salesperson: [null, Validators.required],
      operator: [null],
    });
    this.initUploader();
    this.initUploaderAtt();
    this.setUserType();
    this.setOperator();
    this.getCustomers();
    this.getOperators();
    this.getSalespeople();
    this.getData();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  initUploader() {
    this.uploader = new FileUploader({
      url: `${this.globals.apiUrl}/sales/files`,
      authToken: `JWT ${this._usuarios.getToken()}`,
      isHTML5: true,
      allowedMimeType: ["application/pdf"],
    });

    //Allow CORS
    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
    };

    this.uploader.onAfterAddingFile = (fileItem) => {
      this.fileName = fileItem.file.name;
      // Allow only one file
      this.uploader.clearQueue();
      this.uploader.queue[0] = fileItem;
    };

    //On Upload
    this.uploader.onProgressItem = (item, progress) => {};

    this.uploader.onSuccessItem = (item, response) => {
      const { id: file } = JSON.parse(response);
      this.uploader$.next(file);
    };
  }

  initUploaderAtt() {
    this.uploaderAtt = new FileUploader({
      url: `${this.globals.apiUrl}/sales/files`,
      authToken: `JWT ${this._usuarios.getToken()}`,
      isHTML5: true,
      allowedMimeType: ["application/pdf"],
    });

    //Allow CORS
    this.uploaderAtt.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
    };

    this.uploaderAtt.onAfterAddingFile = (fileItem) => {
      this.fileNameAtt = fileItem.file.name;
      // Allow only one file
      this.uploaderAtt.clearQueue();
      this.uploaderAtt.queue[0] = fileItem;
    };

    //On Upload
    this.uploaderAtt.onSuccessItem = (item, response) => {
      const { id: file } = JSON.parse(response);
      this.uploaderAtt$.next(file);
    };
  }

  setUserType() {
    const user = this._usuarios.getCurrentUser();
    if (!user) return;
    if (user["tipo"] === "salesperson") {
      this.salesperson = user;
      this.form.controls["salesperson"].setValue(this.salesperson.id);
    }
    if (user["tipo"] === "operator") {
      this.operator = user;
      this.form.controls["operator"].setValue(this.operator.id);
    }
  }

  setOperator() {
    const user = this._usuarios.getCurrentUser();
    if (user && user["tipo"] === "operator") this.operator = user;
    if (this.operator)
      this.form.controls["operator"].setValue(this.operator.id);
  }

  //REQ DATA
  getData() {
    const { id } = this._modal.get(CREATE_SALE_MODAL_ID).getData();
    if (!id) return;
    this.data$(id).subscribe(
      (data) => {
        this.data = data;
        this.form.patchValue({
          ...data,
          date: new Date(data.date),
          customer: data.customer.id,
        });
        if (data.file) this.fileName = data.file.name;
        if (data.fileAtt) this.fileNameAtt = data.fileAtt.name;
        if (data.customer && !data.customer.isProspect)
          this.customers = [data.customer, ...this.customers];
      },
      (error) =>
        this._toast.errorToastr(
          "Error getting sale data, verify your internet connection.",
          "Error"
        )
    );
  }

  getCustomers() {
    this.customers$().subscribe(
      (data: any) => (this.customers = [...data, ...this.customers]),
      (error) =>
        this._toast.errorToastr(
          "Error getting customers, verify your internet connection.",
          "Error"
        )
    );
  }

  getOperators() {
    this.operators$().subscribe(
      (data: any) => (this.operators = data),
      (error) =>
        this._toast.errorToastr(
          "Error getting operators, verify your internet connection.",
          "Error"
        )
    );
  }

  getSalespeople() {
    this.salespeople$().subscribe(
      (data: any) => (this.salespeople = data),
      (error) =>
        this._toast.errorToastr(
          "Error getting salespeople, verify your internet connection.",
          "Error"
        )
    );
  }

  downloadFile(att?: boolean) {
    this.file$(att ? this.data.fileAtt.id : this.data.file.id).subscribe(
      (data) => {
        this.saveDownloadedFile(data, att ? this.fileNameAtt : this.fileName);
        this._toast.successToastr("File downloaded.");
      },
      (error) =>
        this._toast.errorToastr(
          "Error trying to download file, verify your internet connection.",
          "Error"
        )
    );
  }

  uploadFile() {
    return new Promise((res, rej) => {
      this.uploader$.pipe(skip(1), takeUntil(this.onDestroy$)).subscribe(
        (value) => res(value),
        (error) => rej(error)
      );
      this.uploader.uploadAll();
    });
  }

  uploadFileAtt() {
    return new Promise((res, rej) => {
      this.uploaderAtt$.pipe(skip(1), takeUntil(this.onDestroy$)).subscribe(
        (value) => res(value),
        (error) => {
          rej(error);
        }
      );
      this.uploaderAtt.uploadAll();
    });
  }

  //ACTIONS
  async onSubmit() {
    if (this.form.invalid)
      return this._toast.errorToastr("Error in form.", "Error");

    if (this.uploader.queue.length) await this.uploadFile();

    if (this.uploaderAtt.queue.length) await this.uploadFileAtt();

    this.data ? this.update() : this.create();
  }

  create() {
    const data = this.form.value;
    if (this.uploader$.value) data.file = this.uploader$.value;
    if (this.uploaderAtt$.value) data.fileAtt = this.uploaderAtt$.value;
    this.create$(data).subscribe(
      () => {
        this._toast.successToastr("Sale added successfully.");
        this.close(true);
      },
      (error) =>
        this._toast.errorToastr(
          "Error adding sale, verify your internet connection.",
          "Error"
        )
    );
  }

  update() {
    if (!this.data) return this._toast.errorToastr("Not sale data.", "Error");
    const data = this.form.value;
    if (this.uploader$.value) data.file = this.uploader$.value;
    if (this.uploaderAtt$.value) data.fileAtt = this.uploaderAtt$.value;
    this.update$(this.data.id, data).subscribe(
      () => {
        this._toast.successToastr("Sale edited successfully.");
        this.close(true);
      },
      (error) =>
        this._toast.errorToastr(
          "Error editing sale, verify your internet connection.",
          "Error"
        )
    );
  }

  close(created?: boolean) {
    const modal = this._modal.get(CREATE_SALE_MODAL_ID);
    modal.removeData().setData({ created }).close();
  }

  //UTILS
  invalidControl(name: string) {
    return this.form.get(name).invalid && this.form.get(name).touched;
  }

  saveDownloadedFile(data, fileName) {
    let a: any = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    let blob = new Blob([data], { type: "octet/stream" }),
      url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }
}
