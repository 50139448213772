import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TextLocateService } from "app/services/text-locate.service";
import { ToastrManager } from "ng6-toastr-notifications";
import { NgxSmartModalComponent, NgxSmartModalService } from "ngx-smart-modal";
import { Subject, of } from "rxjs";
import { finalize, switchMap, takeUntil, tap } from "rxjs/operators";

@Component({
  selector: "app-textlocate-locate",
  templateUrl: "./textlocate-locate.component.html",
  styleUrls: ["./textlocate-locate.component.css"],
})
export class TextlocateLocateComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();
  private MODAL_ID = "TEXTLOCATE_LOCATE_MODAL";
  public placeholder = `Type any special message to the driver:\n the SMS will include your message and this template: \n ------------------------------------------------\n BBA Logistics LLC: Hello <driver name>, a location update has been requested for your current load! Safely click the link below and select "Allow" to send in a one-time location update: <link>`;

  //Req
  private readonly data$ = of({}).pipe(
    tap(() => (this.loading = true)),
    switchMap(() => this._textLocate.getLoad(this.loadId.toString())),
    finalize(() => (this.loading = false)),
    takeUntil(this.onDestroy$)
  );

  private readonly send$ = of({}).pipe(
    tap(() => (this.requesting = true)),
    switchMap(() => {
      if (this.form.invalid) throw "Error en el formulario.";
      const data = this.form.value;
      return this._textLocate.sendMessage(data);
    }),
    finalize(() => (this.requesting = false)),
    takeUntil(this.onDestroy$)
  );

  //Status
  public pagination = {
    page: 0,
    limit: 0,
    count: 0,
    skip: function () {
      this.page * this.limit;
    },
    limitOptions: [20, 50, 100],
  };

  //Data
  public load: any;
  public loadId: number;
  public textlocateId: number;
  public carrier: string;
  public carrierName: string;
  public loading: boolean;
  public requesting: boolean;

  //Form
  public form: FormGroup;

  get disabled() {
    return this.form.invalid || this.requesting;
  }

  constructor(
    private _textLocate: TextLocateService,
    private _modal: NgxSmartModalService,
    private fb: FormBuilder,
    private _toast: ToastrManager
  ) {}

  ngOnInit() {
    this.initForm();
    this.initData();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  initForm() {
    this.form = this.fb.group({
      loadCarrier: [null, [Validators.required]],
      message: [""],
    });
  }

  initData() {
    const modal = this._modal.get(this.MODAL_ID);
    this.setData(modal);
    this._modal
      .get(this.MODAL_ID)
      .onDataAdded.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.setData(this._modal.get(this.MODAL_ID)));
  }

  setData(modal: NgxSmartModalComponent) {
    const { load, textlocateId, carrier, updated, carrierName } =
      modal.getData();
    if (updated) return;
    this.loadId = load;
    this.textlocateId = textlocateId;
    this.carrier = carrier;
    this.carrierName = carrierName;
    if (textlocateId) {
      this.loading = false;
      this.form = this.fb.group({
        textlocateId: [textlocateId, [Validators.required]],
        message: [""],
      });
    } else {
      this.initForm();
      this.getData();
    }
  }

  getData() {
    this.data$.subscribe((load) => {
      this.load = load;
    });
  }

  send() {
    this.send$.subscribe(
      (res: any) => {
        this._toast.successToastr(`Message sent to ${res.to}.`);
        this.form.controls["message"].reset();
        this._modal
          .get(this.MODAL_ID)
          .setData(
            { ...this._modal.get(this.MODAL_ID).getData(), updated: true },
            true
          );
      },
      (err) => this._toast.errorToastr("Error trying to send message")
    );
  }
}
