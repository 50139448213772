import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisualizePdfComponent } from './visualize-pdf.component';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

@NgModule({
  imports: [
    CommonModule,
    NgxSmartModalModule,
    NgxExtendedPdfViewerModule
  ],
  declarations: [VisualizePdfComponent],
  exports: [VisualizePdfComponent]
})
export class VisualizePdfModule { }
