import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Globals } from "./../globals";
import { Router } from "@angular/router";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import "rxjs/add/operator/map";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class LoadsService {
  constructor(
    private http: HttpClient,
    private globals: Globals,
    private router: Router
  ) {}

  getLoads(
    opts?: {
      page?: number;
      limit?: number;
      search?: string;
      user?: number;
    },
    filters?: any
  ) {
    return this.http.post<[any[], number]>(
      `${this.globals.apiUrl}/loads`,
      filters,
      {
        params: <any>opts,
      }
    );
  }


  getLoadsExcel(
    filters?: any
  ) {
    return this.http.post<Blob>(
      `${this.globals.apiUrl}/loads/loads-excel`,
      filters,
      {
        responseType: "blob" as "json",
      }
    );
  }

  getAccCustomer(
    opts?: {
      page?: number;
      limit?: number;
      status?: "paid" | "pending";
      search?: string;
    },
    filters?: any
  ) {
    return this.http.post<[any[], number]>(
      `${this.globals.apiUrl}/loads/acc-customer`,
      filters,
      {
        params: <any>opts,
      }
    );
  }

  getAccCustomerExcel(
    opts?: {
      status?: "paid" | "pending";
      search?: string;
    },
    filters?: any
  ) {
    return this.http.post<Blob>(
      `${this.globals.apiUrl}/loads/acc-customer-excel`,
      filters,
      {
        params: <any>opts,
        responseType: "blob" as "json",
      }
    );
  }

  getAccCarriers(
    opts?: {
      page?: number;
      limit?: number;
      type?: "carriers" | "warehouses";
      status?: "paid" | "pending";
      search?: string;
    },
    filters?: any
  ) {
    return this.http.post<[any[], number]>(
      `${this.globals.apiUrl}/loads/acc-carriers`,
      filters,
      {
        params: <any>opts,
      }
    );
  }

  getAccCarriersExcel(
    opts?: {
      status?: "paid" | "pending";
      search?: string;
      type?: "carriers" | "warehouses";
    },
    filters?: any
  ) {
    return this.http.post<Blob>(
      `${this.globals.apiUrl}/loads/acc-carriers-excel`,
      filters,
      {
        params: <any>opts,
        responseType: "blob" as "json",
      }
    );
  }

  get(filters: any = {}) {
    filters = JSON.stringify(filters);
    return this.http
      .get(`${this.globals.apiUrl}/loads/get`, {
        params: {
          filters,
        },
      })
      .pipe(catchError(this.handleError));
  }

  recover(load) {
    return this.http
      .post(`${this.globals.apiUrl}/loads/recover`, { load })
      .pipe(catchError(this.handleError));
  }

  getAll(filterObj) {
    return this.http
      .get(`${this.globals.apiUrl}/loads/getAll`, {
        params: {
          filterObj,
        },
      })
      .pipe(catchError(this.handleError));
  }

  getLoadToMsg(load: string) {
    return this.http.get(`${this.globals.apiUrl}/loads/getLoadToMsg`, {
      params: { load },
    });
  }

  getLoadsAccCustomers(obj) {
    return this.http
      .get(`${this.globals.apiUrl}/loads/loadsAccCustomers`, {
        params: {
          obj: obj,
        },
      })
      .pipe(catchError(this.handleError));
  }

  getLoadAccounting(loadId) {
    return this.http
      .get(`${this.globals.apiUrl}/loads/getLoadAccounting`, {
        params: {
          loadId: loadId,
        },
      })
      .pipe(catchError(this.handleError));
  }

  getById(obj) {
    return this.http
      .post(`${this.globals.apiUrl}/loads/getById`, {
        obj: obj,
      })
      .pipe(catchError(this.handleError));
  }

  getLoadForFormats(id) {
    return this.http
      .get(`${this.globals.apiUrl}/loads/getLoadForFormats`, {
        params: {
          id: id,
        },
      })
      .pipe(catchError(this.handleError));
  }

  getLastNumbers() {
    return this.http
      .get(`${this.globals.apiUrl}/loads/getLastNumbers`)
      .pipe(catchError(this.handleError));
  }

  getShippers() {
    return this.http
      .get(`${this.globals.apiUrl}/loads/getShippers`)
      .pipe(catchError(this.handleError));
  }

  getConsignees() {
    return this.http
      .get(`${this.globals.apiUrl}/loads/getConsignees`)
      .pipe(catchError(this.handleError));
  }

  getWerehouses() {
    return this.http
      .get(`${this.globals.apiUrl}/loads/getWerehouses`)
      .pipe(catchError(this.handleError));
  }

  getShippersCountries() {
    return this.http.get<any>(`${this.globals.apiUrl}/loads/shippersCountries`);
  }

  getConsigneesCountries() {
    return this.http.get<any>(
      `${this.globals.apiUrl}/loads/consigneesCountries`
    );
  }

  searchShippersCities(search) {
    if (search) {
      return this.http.get<any>(
        `${this.globals.apiUrl}/loads/searchShippersCities`,
        {
          params: { search },
        }
      );
    } else {
      return of([]);
    }
  }
  searchConsigneesCities(search) {
    if (search) {
      return this.http.get<any>(
        `${this.globals.apiUrl}/loads/searchConsigneesCities`,
        {
          params: { search },
        }
      );
    } else {
      return of([]);
    }
  }
  // NO EN USO TODAVIA
  searchShippers(search, type) {
    if (search) {
      return this.http
        .get<any>(`${this.globals.apiUrl}/loads/searchShippers`, {
          params: { search: search, type: type },
        })
        .pipe(map((rsp) => rsp.shippers));
    } else {
      return of([]);
    }
  }

  searchConsignees(search, type) {
    if (search) {
      return this.http
        .get<any>(`${this.globals.apiUrl}/loads/searchConsignees`, {
          params: { search: search, type: type },
        })
        .pipe(map((rsp) => rsp.consignees));
    } else {
      return of([]);
    }
  }

  searchWarehouses(search) {
    if (search) {
      return this.http
        .get<any>(`${this.globals.apiUrl}/loads/searchWarehouses`, {
          params: { search: search },
        })
        .pipe(map((rsp) => rsp.warehouses));
    } else {
      return of([]);
    }
  }

  getLogs(load) {
    return this.http
      .post(`${this.globals.apiUrl}/loads/getLogs`, {
        load: load,
      })
      .pipe(catchError(this.handleError));
  }

  add(load: any) {
    return this.http
      .post(`${this.globals.apiUrl}/loads/add`, load)
      .pipe(catchError(this.handleError));
  }

  edit(id: number | string, load: any) {
    return this.http
      .post(`${this.globals.apiUrl}/loads/edit`, load)
      .pipe(catchError(this.handleError));
  }

  addLog(log) {
    return this.http
      .post(`${this.globals.apiUrl}/loads/addLog`, {
        log: log,
      })
      .pipe(catchError(this.handleError));
  }

  addPayment(payment, sendMail, customer?, carrier?) {
    return this.http
      .post(`${this.globals.apiUrl}/loads/addPayment`, {
        payment,
        customer,
        carrier,
        sendMail,
      })
      .pipe(catchError(this.handleError));
  }

  addCharge(charge, sendMail, customer?, carrier?) {
    return this.http
      .post(`${this.globals.apiUrl}/loads/addCharge`, {
        charge,
        customer,
        carrier,
        sendMail,
      })
      .pipe(catchError(this.handleError));
  }

  addShippers(shipper) {
    return this.http
      .post(`${this.globals.apiUrl}/loads/addShippers`, {
        shipper: shipper,
      })
      .pipe(catchError(this.handleError));
  }

  addConsignee(consignee) {
    return this.http
      .post(`${this.globals.apiUrl}/loads/addConsignees`, {
        consignee: consignee,
      })
      .pipe(catchError(this.handleError));
  }

  addWerehouse(werehouse) {
    return this.http
      .post(`${this.globals.apiUrl}/loads/addWerehouse`, {
        werehouse: werehouse,
      })
      .pipe(catchError(this.handleError));
  }

  editShipper(shipper) {
    return this.http
      .post(`${this.globals.apiUrl}/loads/editShipper`, {
        shipper: shipper,
      })
      .pipe(catchError(this.handleError));
  }

  editConsignee(consignee) {
    return this.http
      .post(`${this.globals.apiUrl}/loads/editConsignee`, {
        consignee: consignee,
      })
      .pipe(catchError(this.handleError));
  }

  delete(id) {
    return this.http
      .post(`${this.globals.apiUrl}/loads/delete`, {
        id: id,
      })
      .pipe(catchError(this.handleError));
  }

  sendMails(data: any) {
    return this.http
      .post(`${this.globals.apiUrl}/loads/send-mails`, data)
      .pipe(catchError(this.handleError));
  }

  sendInvoiceMails(data: any) {
    return this.http
      .post(`${this.globals.apiUrl}/loads/sendInvoiceMails`, data)
      .pipe(catchError(this.handleError));
  }

  getHistory(data) {
    return this.http
      .get(`${this.globals.apiUrl}/loads/getHistory`, {
        params: {
          data: data,
        },
      })
      .pipe(catchError(this.handleError));
  }

  downloadFile(loadId, carrierOrWarehouse) {
    return this.http
      .get(`${this.globals.apiUrl}/loads/downloadFile`, {
        params: { loadId: loadId, carrierOrWarehouse: carrierOrWarehouse },
        responseType: "arraybuffer",
      })
      .map((res: ArrayBuffer) => {
        return res;
      });
  }

  downloadInvoiceFile(loadId) {
    return this.http
      .get(`${this.globals.apiUrl}/loads/downloadInvoiceFile`, {
        params: { loadId: loadId },
        responseType: "arraybuffer",
      })
      .map((res: ArrayBuffer) => {
        return res;
      });
  }

  downloadAccountingFile(obj) {
    return this.http
      .get(`${this.globals.apiUrl}/loads/downloadAccountingFile`, {
        params: { obj: obj },
        responseType: "arraybuffer",
      })
      .map((res: ArrayBuffer) => {
        return res;
      });
  }

  downloadPaymentFile(fileId) {
    return this.http
      .get(`${this.globals.apiUrl}/loads/downloadPaymentFile`, {
        params: { fileId: fileId },
        responseType: "arraybuffer",
      })
      .map((res: ArrayBuffer) => {
        return res;
      });
  }
  downloadProofFile(fileId, type, loadId) {
    return this.http
      .get(`${this.globals.apiUrl}/loads/downloadProofFile`, {
        params: { fileId, type, loadId },
        responseType: "arraybuffer",
      })
      .map((res: ArrayBuffer) => {
        return res;
      });
  }

  deleteUploadFile(id) {
    return this.http
      .post(`${this.globals.apiUrl}/loads/deleteUploadFile`, {
        id: id,
      })
      .pipe(catchError(this.handleError));
  }

  getUploadedFiles(load) {
    return this.http
      .get(`${this.globals.apiUrl}/loads/getUploadedFiles`, {
        params: {
          load: load,
        },
      })
      .pipe(catchError(this.handleError));
  }

  getUploadedFilesDispatch(load) {
    return this.http
      .get(`${this.globals.apiUrl}/loads/getUploadedFilesDispatch`, {
        params: {
          load: load,
        },
      })
      .pipe(catchError(this.handleError));
  }

  getPaymentFileNameForDownload(id) {
    return this.http
      .get(`${this.globals.apiUrl}/loads/getPaymentFileNameForDownload`, {
        params: {
          id: id,
        },
      })
      .pipe(catchError(this.handleError));
  }

  carriersWarehousesAcc(obj?) {
    obj = JSON.stringify(obj || {});
    return this.http
      .get(`${this.globals.apiUrl}/loads/carriersWarehousesAcc`, {
        params: { obj },
      })
      .pipe(catchError(this.handleError));
  }

  resetCarrierInvoice(type, id) {
    return this.http
      .post(`${this.globals.apiUrl}/loads/resetCarrierInvoice`, {
        type,
        id,
      })
      .pipe(catchError(this.handleError));
  }

  saveCarrierInvoice(data: any) {
    return this.http
      .post(`${this.globals.apiUrl}/loads/saveCarrierInvoice`, data)
      .pipe(catchError(this.handleError));
  }

  generateInvoice(data: any) {
    return this.http
      .post(`${this.globals.apiUrl}/loads/generateInvoice`, data)
      .pipe(catchError(this.handleError));
  }

  deletePayment(id) {
    return this.http
      .post(`${this.globals.apiUrl}/loads/deletePayment`, {
        id: id,
      })
      .pipe(catchError(this.handleError));
  }

  deleteCharge(id) {
    return this.http
      .post(`${this.globals.apiUrl}/loads/deleteCharge`, {
        id: id,
      })
      .pipe(catchError(this.handleError));
  }

  generateSupportFilesPDF(id) {
    return this.http
      .get(`${this.globals.apiUrl}/loads/generateSupportFilesPDF`, {
        params: {
          id: id,
        },
      })
      .pipe(catchError(this.handleError));
  }

  checkIfSupportFilesPDFexist(id: string | number, type: "file" | "invoice") {
    return this.http
      .get(`${this.globals.apiUrl}/loads/checkIfSupportFilesPDFexist`, {
        params: {
          id: id.toString(),
          type,
        },
      })
      .pipe(catchError(this.handleError));
  }

  downloadSupportFilesPDF(id) {
    return this.http
      .get(`${this.globals.apiUrl}/loads/downloadSupportFilesPDF`, {
        params: { id: id },
        responseType: "arraybuffer",
      })
      .map((res: ArrayBuffer) => {
        return res;
      });
  }

  includeFileInSupportFile(id, value) {
    return this.http
      .post(`${this.globals.apiUrl}/loads/includeFileInSupportFile`, {
        id: id,
        value: value,
      })
      .pipe(catchError(this.handleError));
  }

  getLoadCarrierForFormat(id, type) {
    return this.http
      .get(`${this.globals.apiUrl}/loads/getLoadCarrierForFormat`, {
        params: {
          id,
          type,
        },
      })
      .pipe(catchError(this.handleError));
  }

  downloadSupportZip(
    opts?: {
      status?: "paid" | "pending";
      search?: string;
    },
    filters?: any
  ) {
    return this.http
      .post<Blob>(
        `${this.globals.apiUrl}/loads/download-support-zip`,
        filters,
        {
          responseType: "blob" as "json",
          params: <any>opts,
        }
      )
      .pipe(catchError(this.handleError));
  }

  cloneLoad(quantity, id) {
    return this.http
      .post(`${this.globals.apiUrl}/loads/cloneLoad`, {
        quantity,
        id
      })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(`Backend returned error ${error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
}
