import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CreateIncomingLoadModalComponent } from "./create-incoming-load-modal.component";
import { ReactiveFormsModule } from "@angular/forms";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { JwBootstrapSwitchNg2Module } from "jw-bootstrap-switch-ng2";
import { NgSelectModule } from "@ng-select/ng-select";
import { QuillModule } from "ngx-quill";

@NgModule({
  declarations: [CreateIncomingLoadModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxSmartModalModule,
    BsDatepickerModule,
    JwBootstrapSwitchNg2Module,
    NgSelectModule,
    QuillModule.forRoot(),
  ],
})
export class CreateIncomingLoadModalModule {}
