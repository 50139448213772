import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CreateCarrierComponent } from "./create-carrier.component";
import { ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { ToastrModule } from "ng6-toastr-notifications";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { JwBootstrapSwitchNg2Module } from "jw-bootstrap-switch-ng2";

@NgModule({
  declarations: [CreateCarrierComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxSmartModalModule,
    ToastrModule,
    BsDatepickerModule,
    JwBootstrapSwitchNg2Module
  ],
  exports: [CreateCarrierComponent],
  entryComponents: [CreateCarrierComponent],
})
export class CreateCarrierModule {}
