import { Component, OnDestroy, OnInit } from "@angular/core";
import { TableColumn } from "@swimlane/ngx-datatable";
import { TextLocateService } from "app/services/text-locate.service";
import { NgxSmartModalComponent, NgxSmartModalService } from "ngx-smart-modal";
import { Subject, forkJoin, of } from "rxjs";
import { finalize, switchMap, takeUntil, tap } from "rxjs/operators";
import { TextlocateHistoryComponent } from "./textlocate-history/textlocate-history.component";
import { TextlocateLocateComponent } from "./textlocate-locate/textlocate-locate.component";

@Component({
  selector: "app-textlocate",
  templateUrl: "./textlocate.component.html",
  styleUrls: ["./textlocate.component.css"],
})
export class TextlocateComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();
  private MODAL_ID = "TEXTLOCATE_MODAL";
  private MODAL_HISTORY_ID = "TEXTLOCATE_HISTORY_MODAL";
  private MODAL_LOCATE_ID = "TEXTLOCATE_LOCATE_MODAL";

  //Req
  private readonly data$ = of({}).pipe(
    tap(() => (this.loading = true)),
    switchMap(() =>
      this._textLocate.getLoads(
        this.loadId.toString(),
        this.pagination.page,
        this.pagination.limit
      )
    ),
    finalize(() => (this.loading = false)),
    takeUntil(this.onDestroy$)
  );

  //Status
  public pagination = {
    page: 0,
    limit: 0,
    count: 0,
    skip: function () {
      this.page * this.limit;
    },
    limitOptions: [20, 50, 100],
  };

  //Data
  public loadId: number;
  public load: any;
  public loads: any[];
  public loading: boolean;
  public requesting: boolean;

  //Table
  public columns: TableColumn[];

  constructor(
    private _textLocate: TextLocateService,
    private _modal: NgxSmartModalService
  ) {}

  ngOnInit() {
    this.initData();
    this.initTable();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  initTable() {
    this.pagination.limit = this.pagination.limitOptions[0];
    this.columns = [];
  }

  initData() {
    const modal = this._modal.get(this.MODAL_ID);
    this.setData(modal);
    this._modal
      .get(this.MODAL_ID)
      .onDataAdded.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.setData(this._modal.get(this.MODAL_ID)));
    this._modal
      .get(this.MODAL_ID)
      .onDataRemoved.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.resetData());
  }

  setData(modal: NgxSmartModalComponent) {
    const { load } = modal.getData();
    this.loadId = load;
    this.getData();
  }

  resetData() {
    this.loadId = null;
    this.loads = [];
  }

  getData() {
    this.data$.subscribe(({ loads, count }) => {
      this.loads = loads;
      this.pagination.count = count;
    });
  }

  //Modals
  openHistoryModal(textlocateId, carrier?) {
    this._modal
      .create(this.MODAL_HISTORY_ID, TextlocateHistoryComponent, {
        customClass: "long-modal",
      })
      .removeData()
      .setData({ textlocateId, carrier })
      .open();
  }

  openLocateModal(textlocateId?, carrier?, carrierName?) {
    this._modal
      .create(this.MODAL_LOCATE_ID, TextlocateLocateComponent, {
        customClass: "medium-modal",
      })
      .removeData()
      .setData({ load: this.loadId, textlocateId, carrier, carrierName })
      .open()
      .onAnyCloseEvent.pipe(takeUntil(this.onDestroy$))
      .subscribe(({ _data }) =>
        _data && _data["updated"] ? this.getData() : false
      );
  }

  //Pagination
  onLimitChange(value) {
    this.pagination.limit = parseInt(value);
    this.getData();
  }

  setPage(pageInfo) {
    this.pagination.page = pageInfo.offset;
    this.getData();
  }
}
