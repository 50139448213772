import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from 'app/globals';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: "root",
})
export class AuditsService {
  constructor(private http: HttpClient, private globals: Globals) {}

  getEntities() {
    return this.http
      .get(`${this.globals.apiUrl}/audits/getEntities`, {})
      .pipe(catchError(this.handleError));
  }

  getAll(filters: any) {
    return this.http
      .post<{ total: number; results: any[] }>(
        `${this.globals.apiUrl}/audits/getAll`,
        { filters }
      )
      .pipe(catchError(this.handleError));
  }

  getDetails(activityLog) {
    return this.http
      .post<{ details: any[] }>(`${this.globals.apiUrl}/audits/getDetails`, {
        activityLog,
      })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(`Backend returned error ${error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
}
