import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Globals } from "app/globals";
import { map } from "jquery";
import { of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class BranchesService {
  constructor(private http: HttpClient, private globals: Globals) {}

  get(filters: any = {}) {
    filters = JSON.stringify(filters);
    return this.http
      .get(`${this.globals.apiUrl}/branches/get`, { params: { filters } })
      .pipe(catchError(this.handleError));
  }

  count(filters: any = {}) {
    filters = JSON.stringify(filters);
    return this.http
      .get(`${this.globals.apiUrl}/branches/count`, { params: { filters } })
      .pipe(catchError(this.handleError));
  }

  add(branch: any) {
    return this.http
      .post(`${this.globals.apiUrl}/branches/add`, { branch })
      .pipe(catchError(this.handleError));
  }

  update(branch: any) {
    return this.http
      .post(`${this.globals.apiUrl}/branches/update`, { branch })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(`Backend returned error ${error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
}
