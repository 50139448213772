import { Component, Input, OnInit } from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

export const PDF_MODAL_ID = "PDF_MODAL";

@Component({
  selector: "pdf-modal",
  template: `
    <div class="modal-header p-2">
      <h4 class="modal-title mt-0">File preview</h4>
    </div>
    <div class="modal-body" *ngIf="pdf" style="padding: 0px !important">
      <ngx-extended-pdf-viewer
        [src]="pdf"
        useBrowserLocale="true"
        height="85vh"
        [zoom]="65"
      ></ngx-extended-pdf-viewer>
    </div>
  `,
  styles: [
    `
      pdf-viewer {
        display: block;
        height: 100vh;
        width: 100vw;
      }
      ::ng-deep .pdf-preview .nsm-content {
        height: 95vh !important;
      }

      @media only screen and (max-width: 768px) {
        ::ng-deep .pdf-preview {
          width: 100vw !important;
          max-width: 100vw !important;
          height: 100vh !important;
          max-height: 100vh !important;
          border-radius: 10px !important;
        }

        ::ng-deep .pdf-preview .nsm-content {
          height: 95vh !important;
        }
      }
    `,
  ],
})
export class PdfModalComponent implements OnInit {
  private onDestroy$ = new Subject<void>();

  public pdf: any;

  constructor(private _modal: NgxSmartModalService) {}

  ngOnInit() {
    this.initListeners();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  initListeners() {
    this.onDataChange();
  }

  onDataChange() {
    const modal = this._modal.get(PDF_MODAL_ID);
    this.initData();
    modal.onDataAdded.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.initData();
    });
    modal.onDataRemoved.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.resetData();
    });
  }

  initData() {
    const modal = this._modal.get(PDF_MODAL_ID);
    const { pdf } = modal.getData();
    this.pdf = pdf;
  }

  resetData() {
    this.pdf = null;
  }
}
