import { Component, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AngularTokenService } from 'angular-token';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgxPermissionsService } from 'ngx-permissions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

declare var $: any;

@Component({

  selector: 'login-cmp',
  templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit {
  test : Date = new Date();
  form: FormGroup;
  private toggleButton;
  private sidebarVisible: boolean;
  private nativeElement: Node;
  public disableButtons = false;

  constructor(
    private element: ElementRef,
    private router: Router,
    private angularTokenService: AngularTokenService,
    private toastr: ToastrManager,
    private permissionsService: NgxPermissionsService,
    private formBuilder: FormBuilder
  ) {
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
  }

  ngOnInit() {
    this.checkFullPageBackgroundImage();
    var body = document.getElementsByTagName('body')[0];
    body.classList.add('login-page');
    var navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];

    setTimeout(function () {
      // after 1000 ms we add the class animated to the login/register card
      $('.card').removeClass('card-hidden');
    }, 700);

    this.form = this.formBuilder.group({
      usuario: ['', Validators.required],
      password: ['', Validators.required]
    });
  }


  checkFullPageBackgroundImage() {
    var $page = $('.full-page');
    var image_src = $page.data('image');

    if (image_src !== undefined) {
      var image_container = '<div class="full-page-background" style="background-image: url(' + image_src + ') "/>'
      $page.append(image_container);
    }
  }

  login() {
    let formObj = this.form.value;
    this.angularTokenService.signIn({
      login: formObj.usuario.toLowerCase(),
      password: formObj.password
    }).subscribe(res => {
      localStorage.setItem('jwtToken', JSON.stringify(res.body));
      this.permissionsService.loadPermissions(res.body.user.permisos);
      this.router.navigate(['/dashboard']);
    }, err => {
      this.toastr.errorToastr(err, 'Error', {maxShown: 1, animate: 'slideFromBottom'});
    });
  }

  ngOnDestroy() {
    var body = document.getElementsByTagName('body')[0];
    body.classList.remove('login-page');
  }

  sidebarToggle() {
    var toggleButton = this.toggleButton;
    var body = document.getElementsByTagName('body')[0];
    var sidebar = document.getElementsByClassName('navbar-collapse')[0];
    if (this.sidebarVisible == false) {
      setTimeout(function () {
        toggleButton.classList.add('toggled');
      }, 500);
      body.classList.add('nav-open');
      this.sidebarVisible = true;
    } else {
      this.toggleButton.classList.remove('toggled');
      this.sidebarVisible = false;
      body.classList.remove('nav-open');
    }
  }

  get f(): any { return this.form.controls; }
}
