import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSmartModalModule } from "ngx-smart-modal";
import { DuplicateLoadModalComponent } from './duplicate-load-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxLoadingModule } from "ngx-loading";

@NgModule({
  declarations: [
    DuplicateLoadModalComponent
  ],
  imports: [
    CommonModule,
    NgxSmartModalModule,
    ReactiveFormsModule,
    NgxLoadingModule
  ]
})
export class DuplicateLoadModalModule { }
