import { Component, OnInit } from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";

export const CONFIRMATION_MODAL_ID = "CONFIRM_MODAL";

interface ConfirmationButtons {
  cancel?: { color?: string; text?: string };
  confirm?: { color?: string; text?: string };
}

export interface ConfirmationModalData {
  title: string;
  message: string;
  strongMessage?: string;
  buttons: ConfirmationButtons;
}

@Component({
  selector: "app-confirmation-modal",
  templateUrl: "./confirmation-modal.component.html",
  styleUrls: ["./confirmation-modal.component.css"],
})
export class ConfirmationModalComponent implements OnInit {
  public title: string;
  public message: string;
  public strongMessage: string;
  public buttons: ConfirmationButtons;

  constructor(private _modal: NgxSmartModalService) {}

  ngOnInit() {
    this.getData();
  }

  getData() {
    const { title, message, strongMessage, buttons } = this._modal
      .get(CONFIRMATION_MODAL_ID)
      .getData();
    this.title = title;
    this.message = message;
    this.strongMessage = decodeURIComponent(strongMessage);
    this.buttons = buttons;
  }

  cancel() {
    this._modal.get(CONFIRMATION_MODAL_ID).close();
    this._modal.removeModal(CONFIRMATION_MODAL_ID);
  }

  confirm() {
    this._modal
      .get(CONFIRMATION_MODAL_ID)
      .removeData()
      .setData({ confirmed: true })
      .close();
    this._modal.removeModal(CONFIRMATION_MODAL_ID);
  }
}
