import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ToastrModule } from "ng6-toastr-notifications";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { NgxLoadingModule } from "ngx-loading";
import { VisualizePdfModule } from "app/shared/visualize-pdf/visualize-pdf.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { NgSelectModule } from "@ng-select/ng-select";
import { SendRateModalComponent } from "./send-rate-modal.component";
import { PdfModalModule } from "../../pdf-modal/pdf-modal.module";

@NgModule({
  declarations: [SendRateModalComponent],
  imports: [
    CommonModule,
    ToastrModule.forRoot(),
    NgxSmartModalModule,
    NgxLoadingModule,
    VisualizePdfModule,
    ReactiveFormsModule,
    FormsModule,
    NgxDatatableModule,
    NgSelectModule,
    PdfModalModule,
  ],
  exports: [SendRateModalComponent],
  entryComponents: [SendRateModalComponent],
})
export class SendRateModalModule {}
