import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSmartModalModule } from "ngx-smart-modal";
import { NgxLoadingModule } from "ngx-loading";
import { ActivityLogDetailComponent } from './activity-log-detail.component';

@NgModule({
  declarations: [
    ActivityLogDetailComponent
  ],
  imports: [
    CommonModule,
    NgxSmartModalModule,
    NgxLoadingModule
  ]
})
export class ActivityLogDetailModule { }
