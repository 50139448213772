import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Globals } from "app/globals";
import { BranchesService } from "app/services/branches.service";
import { CustomersService } from "app/services/customers.service";
import { OperatorsService } from "app/services/operators.service";
import { UsuariosService } from "app/services/usuarios.service";
import { ToastrManager } from "ng6-toastr-notifications";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Subject } from "rxjs";
import { finalize, takeUntil } from "rxjs/operators";

export const CREATE_CUSTOMER_MODAL = "CREATE_CUSTOMER_MODAL";

@Component({
  selector: "app-create-customer",
  templateUrl: "./create-customer.component.html",
  styleUrls: ["./create-customer.component.css"],
})
export class CreateCustomerComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<void>();

  public bsConfig: Partial<BsDatepickerConfig> = {
    containerClass: "theme-default",
    maxDate: new Date(),
  };

  //Status
  public loading: boolean;
  public requesting: boolean;

  //Data
  public data: any;
  public branches: any[] = [];
  public operators: any[] = [];
  public usuarios: any[] = [];
  public billingCompanies: any[] = [];
  public paymentMethods = this.globals.paymentMethods;
  public equipmentTypes = this.globals.equipamentTypes;
  public countries = ["MX", "USA"];

  //Forms
  public form: FormGroup;

  constructor(
    private _customers: CustomersService,
    private fb: FormBuilder,
    private _modal: NgxSmartModalService,
    private _toast: ToastrManager,
    private globals: Globals,
    private _usuarios: UsuariosService,
    private _operators: OperatorsService,
    private _branches: BranchesService
  ) {}

  get disabled() {
    return this.form.invalid || this.requesting || this.loading;
  }

  ngOnInit() {
    this.form = this.fb.group({
      name: ["", Validators.required],
      address: ["", Validators.required],
      billing_address: ["", Validators.required],
      rfc: [""],
      telephone: ["", Validators.required],
      email: ["", Validators.required],
      contact: ["", Validators.required],
      operator: [null],
      is_broker: [false],
      seller: null,
      userCustomer: [null],
      doc: [null],
      ariseDoc: [null],
      isProspect: [false],
      paymentTerm: [null],
      branch: [null],
      country: [null],
      billingCompany: [null, Validators.required],
    });
    this.getBranches();
    this.getOperators();
    this.getUsuarios();
    this.getBillingCompanies();
    this.getData();
    this.initListeners();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  initListeners() {}

  getBranches() {
    this._branches
      .get({status: 'activo'})
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (response: any) => (this.branches = response),
        (err) =>
          this._toast.errorToastr(
            "Error getting branches list, verify your internet connection.",
            "Error",
            { maxShown: 1, animate: "slideFromBottom" }
          )
      );
  }

  getBillingCompanies() {
    this._customers
      .getBillingCompanies()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (response: any) => (this.billingCompanies = response),
        (err) =>
          this._toast.errorToastr(
            "Error getting branches list, verify your internet connection.",
            "Error",
            { maxShown: 1, animate: "slideFromBottom" }
          )
      );
  }

  getOperators() {
    this._operators
      .getAll()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (response: any) => (this.operators = response),
        (err) =>
          this._toast.errorToastr(
            "Error getting operators list, verify your internet connection.",
            "Error",
            { maxShown: 1, animate: "slideFromBottom" }
          )
      );
  }

  getUsuarios() {
    this._usuarios
      .getAll()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (response: any) => (this.usuarios = response),
        (err) =>
          this._toast.errorToastr(
            "Error getting users list, verify your internet connection.",
            "Error",
            { maxShown: 1, animate: "slideFromBottom" }
          )
      );
  }

  getData() {
    const { id } = this._modal.getModalData(CREATE_CUSTOMER_MODAL);
    if (!id) return;
    this.loading = true;
    this._customers
      .getById(id)
      .pipe(
        finalize(() => (this.loading = false)),
        takeUntil(this.onDestroy$)
      )
      .subscribe(
        (data) => {
          this.data = data;
          const { userCustomer, branch, operator, billingCompany } = data;
          this.form.patchValue({
            ...data,
            userCustomer: userCustomer ? userCustomer.id : null,
            branch: branch ? branch.id : null,
            operator: operator ? operator.id : null,
            billingCompany: billingCompany ? billingCompany.id : null,
          });
        },
        (error) =>
          this._toast.errorToastr("Error getting customer.", "Error", {
            maxShown: 1,
            animate: "slideFromBottom",
          })
      );
  }

  onSubmit() {
    if (this.form.invalid)
      return this._toast.errorToastr("Invalid Form.", "Error", {
        maxShown: 1,
        animate: "slideFromBottom",
      });
    if (this.data) this.update();
    else this.create();
  }

  formatData() {
    const value = this.form.getRawValue();
    const { user } = this._usuarios.getCurrentUser();
    if (user) value.user = user;
    if (value.rfc) value.rfc = value.rfc.toUpperCase();
    if (value.email && typeof value.email == "string")
      value.email = value.email.split(",").map((email) => email.trim());
    return value;
  }

  create() {
    this.requesting = true;
    this._customers
      .create(this.formatData())
      .pipe(
        finalize(() => (this.requesting = false)),
        takeUntil(this.onDestroy$)
      )
      .subscribe(
        (data) => {
          this._toast.successToastr("Customer added successfully.", "", {
            maxShown: 1,
            animate: "slideFromBottom",
          });
          this._modal
            .get(CREATE_CUSTOMER_MODAL)
            .removeData()
            .setData({ data })
            .close();
        },
        (error) =>
          this._toast.errorToastr("Error adding customer, try again.", "", {
            maxShown: 1,
            animate: "slideFromBottom",
          })
      );
  }

  update() {
    this.requesting = true;
    this._customers
      .update(this.data.id, this.formatData())
      .pipe(
        finalize(() => (this.requesting = false)),
        takeUntil(this.onDestroy$)
      )
      .subscribe(
        (data) => {
          this._toast.successToastr("Customer edited successfully.", "", {
            maxShown: 1,
            animate: "slideFromBottom",
          });
          this._modal
            .get(CREATE_CUSTOMER_MODAL)
            .removeData()
            .setData({ data })
            .close();
        },
        (error) =>
          this._toast.errorToastr("Error editing customer, try again.", "", {
            maxShown: 1,
            animate: "slideFromBottom",
          })
      );
  }

  close() {
    this._modal.get(CREATE_CUSTOMER_MODAL).removeData().close();
  }
}
