import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Globals } from "./../globals";
import { Router } from "@angular/router";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ConfigService {
  constructor(
    private http: HttpClient,
    private globals: Globals,
    private router: Router
  ) {}
  getFile(uuid: string) {
    return this.http
      .get(`${this.globals.apiUrl}/configuration/getFile`, {
        params: { uuid },
        responseType: "blob",
      })
      .pipe(catchError(this.handleError));
  }

  getConfig() {
    return this.http
      .get(`${this.globals.apiUrl}/configuration/getConfig`)
      .pipe(catchError(this.handleError));
  }

  saveConfig(config, file) {
    let formData = new FormData();
    formData.append("config", JSON.stringify(config));
    if (file) formData.append("file", file);
    return this.http
      .post(`${this.globals.apiUrl}/configuration/saveConfig`, formData)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(`Backend returned error ${error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
}
