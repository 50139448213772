import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ConfirmationModalComponent } from "./confirmation-modal.component";
import { NgxSmartModalModule } from "ngx-smart-modal";

@NgModule({
  declarations: [ConfirmationModalComponent],
  imports: [CommonModule, NgxSmartModalModule],
})
export class ConfirmationModalModule {}
