import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { RoutesMxService } from "app/services/routes-mx.service";
import { ToastrManager } from "ng6-toastr-notifications";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Subject, of } from "rxjs";
import { switchMap, finalize, takeUntil, tap } from "rxjs/operators";

export const CREATE_ROUTE_MX_MODAL_ID = "CREATE_ROUTE_MX";
export interface CreateRouteMxData {
  id: number;
}

@Component({
  selector: "app-create-route-mx",
  templateUrl: "./create-route-mx.component.html",
  styleUrls: ["./create-route-mx.component.css"],
})
export class CreateRouteMxComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<void>();

  private readonly data$ = of({}).pipe(
    tap(() => (this.loading = true)),
    switchMap(() => this._routesMx.getById(this.data.id)),
    finalize(() => (this.loading = false)),
    takeUntil(this.onDestroy$)
  );

  private create$ = of({}).pipe(
    tap(() => (this.requesting = true)),
    switchMap(() => {
      if (this.form.invalid) throw "Invalid Form";
      return this._routesMx.create({ ...this.form.value });
    }),
    finalize(() => (this.requesting = false)),
    takeUntil(this.onDestroy$)
  );

  private update$ = of({}).pipe(
    tap(() => (this.requesting = true)),
    switchMap(() => {
      if (this.form.invalid) throw "Invalid Form";
      return this._routesMx.update(this.data.id, this.form.value);
    }),
    finalize(() => (this.requesting = false)),
    takeUntil(this.onDestroy$)
  );

  //Status
  public loading: boolean;
  public requesting: boolean;
  public created: boolean;

  //Form
  public form: FormGroup;

  //Data
  public data: CreateRouteMxData;
  public sticker: any;

  constructor(
    private _routesMx: RoutesMxService,
    private fb: FormBuilder,
    private _modal: NgxSmartModalService,
    private _toast: ToastrManager
  ) {}

  ngOnInit() {
    this.initForm();
    this.initListeners();
    this.getData();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  initForm() {
    this.form = this.fb.group({
      from: [null, [Validators.required]],
      to: [null, [Validators.required]],
      cost: [null, [Validators.required]],
    });
  }

  initListeners() {}

  getData() {
    this.data = this._modal.get(CREATE_ROUTE_MX_MODAL_ID).getData();
    if (!this.data.id) return;
    this.data$.subscribe(
      (data: any) => {
        this.form.patchValue(data);
        this.data = data;
      },
      () =>
        this._toast.errorToastr(
          "Error getting route data, verify your internet connection."
        )
    );
  }

  create() {
    this.create$.subscribe(
      (data) => {
        this._toast.successToastr("Route created correctly.");
        this.data = data;
        this.close(true);
      },
      (error) => this._toast.errorToastr(error)
    );
  }

  edit() {
    this.update$.subscribe(
      (data) => {
        this._toast.successToastr("Route edited correctly.");
        this.data = data;
        this.close(true);
      },
      (error) => this._toast.errorToastr(error)
    );
  }

  close(success?: boolean) {
    this._modal
      .get(CREATE_ROUTE_MX_MODAL_ID)
      .removeData()
      .setData({ success, created: this.created })
      .close();
    this._modal.removeModal(CREATE_ROUTE_MX_MODAL_ID);
  }

  //Utils
  invalidControl(name: string) {
    return this.form.get(name).touched && this.form.get(name).invalid;
  }
}
