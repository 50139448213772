import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Globals } from "app/globals";

@Injectable({
  providedIn: "root",
})
export class RoutesMxService {
  private readonly ROUTE = `${this.globals.apiUrl}/routes-mx`;

  constructor(private http: HttpClient, private globals: Globals) {}

  get(opts?: { page?: number; limit?: number; search?: string }) {
    return this.http.get<[any[], number]>(`${this.ROUTE}`, {
      params: <any>opts,
    });
  }

  getById(id: number) {
    return this.http.get<any>(`${this.ROUTE}/${id}`);
  }

  create(data: any) {
    return this.http.post<any>(`${this.ROUTE}`, data);
  }

  update(id: number, data: any) {
    return this.http.post<any>(`${this.ROUTE}/${id}`, data);
  }

  delete(id: number) {
    return this.http.delete<any>(`${this.ROUTE}/${id}`);
  }
}
