import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoadTrailersComponent } from "./load-trailers.component";
import { ReactiveFormsModule } from "@angular/forms";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { NgSelectModule } from "@ng-select/ng-select";

@NgModule({
  declarations: [LoadTrailersComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BsDatepickerModule,
    NgxDatatableModule,
    NgSelectModule,
  ],
  exports: [LoadTrailersComponent],
  entryComponents: [LoadTrailersComponent],
})
export class LoadTrailersModule {}
