import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import * as _ from "lodash";
import { ToastrManager } from "ng6-toastr-notifications";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Subject, combineLatest } from "rxjs";
import { debounceTime, distinctUntilChanged, startWith, takeUntil } from "rxjs/operators";

export const MARS_ITEMS_MODAL = "MARS_ITEMS_MODAL_ID";

@Component({
  selector: 'app-mars-items',
  templateUrl: './mars-items.component.html',
  styleUrls: ['./mars-items.component.css']
})
export class MarsItemsComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<void>();

  public items: any[] = [];
  public amountCtrl: FormControl;
  public itemForm: FormGroup;
  public selectedItem: number;
  public toRemove: number = -1;

  constructor(
    private _modal: NgxSmartModalService,
    private fb: FormBuilder,
    private _toast: ToastrManager
  ) {}

  ngOnInit() {
    this.itemForm = this.fb.group({
      quantity: [null, [Validators.required]],
      itemCode: [null, [Validators.required]],
      description: [null, [Validators.required]],
      unitPrice: [null, [Validators.required]],
    });
    this.amountCtrl = this.fb.control({ disabled: true, value: null });
    combineLatest([
      this.itemForm.controls["quantity"].valueChanges.pipe(startWith(0)),
      this.itemForm.controls["unitPrice"].valueChanges.pipe(startWith(0)),
    ])
      .pipe(distinctUntilChanged(), debounceTime(50), takeUntil(this.onDestroy$))
      .subscribe(([quantity, unitPrice]) => {
        this.calculateAmount(quantity, unitPrice);
      });
    this.getData();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  calculateAmount(quantity: number, unitPrice: number) {
    this.amountCtrl.setValue((quantity || 0) * (unitPrice || 0));
  }

  getData() {
    const data = this._modal.get(MARS_ITEMS_MODAL).getData();
    if (!data) return;
    const { items } = data;
    if (Array.isArray(items)) {
      this.items = items;
    } else {
      this.items = [];
    }
  }

  setEdit(idx: number) {
    this.selectedItem = idx;
    this.itemForm.patchValue(this.items[idx]);
  }

  manageItems() {
    if (this.selectedItem) this.editItem();
    else this.addItem();
  }

  resetItemForm() {
    this.itemForm.reset();
    this.selectedItem = -1;
  }

  addItem() {
    if (this.itemForm.invalid) return;
    this.items = [...this.items, this.itemForm.getRawValue()];
    this.resetItemForm();
  }

  editItem() {
    if (this.itemForm.invalid) return;
    this.items[this.selectedItem] = this.itemForm.getRawValue();
    this.items = [...this.items];
    this.resetItemForm();
  }

  askRemoveItem(idx: number) {
    this.toRemove = idx;
    this._modal.get('deleteMarsItem').open();
  }

  removeItem() {
    if(this.toRemove != -1) {
      this.items = this.items.filter((_, i) => i != this.toRemove);
      this.closeDeleteItem();
    }
  }

  closeDeleteItem() {
    this._modal.get('deleteMarsItem').close();
    this.toRemove = -1;
  }

  edit() {
    this._modal
      .get(MARS_ITEMS_MODAL)
      .removeData()
      .setData({ items: this.items })
      .close();
  }

  close() {
    this._modal.get(MARS_ITEMS_MODAL).removeData().setData({}).close();
  }
}
