import { Component, OnInit } from '@angular/core';
import { AuditsService } from 'app/services/audits.service';
import { LoadsService } from 'app/services/loads.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Subject, of } from 'rxjs';
import { finalize, switchMap, takeUntil, tap } from "rxjs/operators";
export const ACTIVITY_LOG_DETAIL_MODAL = "ACTIVITY_LOG_DETAIL_MODAL";

@Component({
  selector: 'app-activity-log-detail',
  templateUrl: './activity-log-detail.component.html',
  styleUrls: ['./activity-log-detail.component.css']
})
export class ActivityLogDetailComponent implements OnInit {
  public activityLog: any;
  public details: any[] = [];
  public loading: boolean;
  private readonly onDestroy$ = new Subject<void>();

  constructor(
    private _modal: NgxSmartModalService,
    private _toast: ToastrManager,
    private _audits: AuditsService,
  ) { }

  private readonly getDetails$ = (activityLog) =>
    of({}).pipe(
      tap(() => (this.loading = true)),
      switchMap(() => {
        return this._audits.getDetails(activityLog);
      }),
      finalize(() => (this.loading = false)),
      takeUntil(this.onDestroy$)
    );

  ngOnInit() {
    this.getData();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getData() {
    const { activityLog } = this._modal
      .get(ACTIVITY_LOG_DETAIL_MODAL)
      .getData();
      this.activityLog = activityLog;
      this.getDetails();
  }

  getDetails() {
    this.getDetails$(this.activityLog).subscribe(
      (details: any) => {
        this.activityLog.details = details.map((log) => ({
          ...log,
          dataEntries: log.dataEntries || []
        }));
      },
      (error) => this._toast.errorToastr(error)
    );
  }

  closeModal() {
    this._modal.get(ACTIVITY_LOG_DETAIL_MODAL).close();
  }
}
