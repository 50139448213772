import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { Globals } from "app/globals";
import * as _ from "lodash";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Subject } from "rxjs";
import * as moment from "moment";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";

export const LOAD_TRAILERS_MODAL = "LOAD_TRAILERS_MODAL_ID";

@Component({
  selector: "app-load-trailers",
  templateUrl: "./load-trailers.component.html",
  styleUrls: ["./load-trailers.component.css"],
})
export class LoadTrailersComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<void>();

  public bsConfig: Partial<BsDatepickerConfig> = {
    containerClass: "theme-default"
  };

  public trailers: any[] = [];
  public trailerForm: FormGroup;
  public selectedTrailer: number;
  public hours = this.globals.hours;

  constructor(
    private _modal: NgxSmartModalService,
    private fb: FormBuilder,
    private globals: Globals
  ) {}

  ngOnInit() {
    this.trailerForm = this.fb.group({
      scac: [null],
      splcOrigin: [{ disabled: true, value: "921000701" }],
      vin: [null],
      pickupDate: [null],
      pickupTime: [null],
      routeOrigin: [{ disabled: true, value: "G" }],
      destinationRoute: [null],
      destinationSplc: [{ disabled: true, value: "699380200" }],
      deliveryDate: [null],
      deliveryTime: [null],
      departureArrival: [{ disabled: true, value: "A" }],
    });
    this.getData();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getData() {
    const data = this._modal.get(LOAD_TRAILERS_MODAL).getData();
    if (!data) return;
    const { trailers } = data;
    this.trailers = trailers || [];
  }

  setEdit(idx: number) {
    this.selectedTrailer = idx;
    const value = this.trailers[idx];
    if (value.pickupDate) {
      value.pickupTime = moment(value.pickupDate).format("HH:mm");
      value.pickupDate = moment(value.pickupDate).toDate();
    }
    if (value.deliveryDate) {
      value.deliveryTime = moment(value.deliveryDate).format("HH:mm");
      value.deliveryDate = moment(value.deliveryDate).toDate();
    }

    this.trailerForm.patchValue(value);
  }

  manageTrailers() {
    if (this.selectedTrailer) this.editTrailer();
    else this.addTrailer();
  }

  resetTrailerForm() {
    this.trailerForm.reset();
    this.trailerForm.patchValue({
      splcOrigin: "921000701",
      routeOrigin: "G",
      destinationSplc: "699380200",
      departureArrival: "A",
    });
    this.selectedTrailer = -1;
  }

  addTrailer() {
    const { pickupDate, deliveryDate, pickupTime, deliveryTime, ...value } =
      this.trailerForm.getRawValue();
    const setTime = function (date, time) {
      const [hour, minute] = time.split(":");
      if (time) return moment(date).set({ hour, minute });
      else return moment(date);
    };
    const newTrailer = {
      ...value,
      deliveryDate: setTime(deliveryDate, deliveryTime).utc(true).toISOString(),
      pickupDate: setTime(pickupDate, pickupTime).utc(true).toISOString(),
    };

    this.trailers = [...this.trailers, newTrailer];
    this.resetTrailerForm();
  }

  editTrailer() {
    const { pickupDate, deliveryDate, pickupTime, deliveryTime, ...value } =
      this.trailerForm.getRawValue();
    const setTime = function (date, time) {
      const [hour, minute] = time.split(":");
      if (time) return moment(date).set({ hour, minute });
      else return moment(date);
    };
    const editedTrailer = {
      ...value,
      deliveryDate: setTime(deliveryDate, deliveryTime).utc(true).toISOString(),
      pickupDate: setTime(pickupDate, pickupTime).utc(true).toISOString(),
    };
    this.trailers[this.selectedTrailer] = editedTrailer;
    this.trailers = [...this.trailers];
    this.resetTrailerForm();
  }

  removeTrailer(idx: number) {
    this.trailers = this.trailers.filter((_, i) => i != idx);
  }

  edit() {
    this._modal
      .get(LOAD_TRAILERS_MODAL)
      .removeData()
      .setData({ trailers: this.trailers })
      .close();
  }

  close() {
    this._modal.get(LOAD_TRAILERS_MODAL).removeData().setData({}).close();
  }
}
