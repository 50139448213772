import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Globals } from "./../globals";
import { Router } from "@angular/router";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { map } from "rxjs/operators";
import { of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CustomersService {
  constructor(
    private http: HttpClient,
    private globals: Globals,
    private router: Router
  ) {}

  getAll() {
    // return this.http.get(`${this.globals.apiUrl}/customers?status=activo`).pipe(catchError(this.handleError));
    return this.http
      .get(`${this.globals.apiUrl}/customers/getAll`)
      .pipe(catchError(this.handleError));
  }

  getById(id: number) {
    return this.http
      .get<any>(`${this.globals.apiUrl}/customers/${id}`)
      .pipe(catchError(this.handleError));
  }

  getAllProspects() {
    return this.http
      .get(`${this.globals.apiUrl}/customers/getAllProspects`)
      .pipe(catchError(this.handleError));
  }

  search(search) {
    if (search) {
      return this.http
        .get<any>(`${this.globals.apiUrl}/customers/search`, {
          params: { search: search },
        })
        .pipe(map((rsp) => rsp.customers));
    } else {
      return of([]);
    }
  }

  create(customer: any) {
    return this.http
      .post<any>(`${this.globals.apiUrl}/customers`, customer)
      .pipe(catchError(this.handleError));
  }

  update(id: number, customer: any) {
    return this.http
      .post<any>(`${this.globals.apiUrl}/customers/${id}`, customer)
      .pipe(catchError(this.handleError));
  }

  delete(id) {
    return this.http
      .post(`${this.globals.apiUrl}/customers/delete`, {
        id: id,
      })
      .pipe(catchError(this.handleError));
  }

  getFromUser(user: string) {
    return this.http
      .get(`${this.globals.apiUrl}/customers/user/${user}`)
      .pipe(catchError(this.handleError));
  }

  getBillingCompanies() {
    return this.http
    .get(`${this.globals.apiUrl}/customers/getBillingCompanies`)
    .pipe(catchError(this.handleError));
  }

  getFiles(customer: string) {
    return this.http
      .get(`${this.globals.apiUrl}/customers/files`, {
        params: { customer },
      })
      .pipe(catchError(this.handleError));
  }

  deleteFile(id: string) {
    return this.http
      .delete(`${this.globals.apiUrl}/customers/file`, { params: { id } })
      .pipe(catchError(this.handleError));
  }

  downloadFile(uuid: string) {
    return this.http
      .get(`${this.globals.apiUrl}/customers/file`, {
        params: { uuid },
        responseType: "blob",
      })
      .map((res: Blob) => {
        return res;
      });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(`Backend returned error ${error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
}
