import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoadsFiltersComponent } from "./loads-filters.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { ToastrModule } from "ng6-toastr-notifications";

@NgModule({
  declarations: [LoadsFiltersComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSmartModalModule,
    BsDatepickerModule,
    ToastrModule,
    NgxSmartModalModule,
  ],
  exports: [LoadsFiltersComponent],
})
export class LoadsFiltersModule {}
