import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import * as _ from "lodash";

@Injectable({
  providedIn: "root",
})
export class FiltersService {
  private readonly _filters$ = new BehaviorSubject<any>({ status: "activo" });
  public readonly filtersChange = this._filters$.asObservable();

  public extraData: any = {};

  private hasStatus: boolean;

  constructor() {
    this.hasStatus = !!this._filters$.value["status"];
  }

  get filters() {
    return this._filters$.value;
  }

  set filters(filters: any) {
    this._filters$.next(this.filterObj(filters || {}));
    this.hasStatus = !!filters["status"];
  }

  get hasActiveFilters() {
    return (
      !!Object.keys(this.filters || {}).filter((key) => key != "status")
        .length || !this.hasStatus
    );
  }

  private filterObj(obj) {
    const retObj = _.cloneDeep(obj);
    Object.keys(retObj).forEach((key) => {
      if (_.isObject(retObj[key]) && !_.isDate(retObj[key]))
        retObj[key] = this.filterObj(retObj[key]);
      if (
        _.isObject(retObj[key]) &&
        _.isEmpty(retObj[key]) &&
        !_.isDate(retObj[key])
      )
        delete retObj[key];
      if (_.isNil(retObj[key])) delete retObj[key];
    });
    return retObj;
  }
}
