import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MarsItemsComponent } from "./mars-items.component";
import { ReactiveFormsModule } from "@angular/forms";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { ToastrModule } from "ng6-toastr-notifications";
import { NgxSmartModalModule } from "ngx-smart-modal";

@NgModule({
  declarations: [MarsItemsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BsDatepickerModule,
    NgxDatatableModule,
    ToastrModule,
    NgxSmartModalModule
  ],
  exports: [MarsItemsComponent],
  entryComponents: [MarsItemsComponent],
})
export class MarsItemsModule {}
