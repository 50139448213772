import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoadCarrierMsgComponent } from "./load-carrier-msg.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { ToastrModule } from "ng6-toastr-notifications";
import { AgmCoreModule } from "@agm/core";
import { LocateMsgHistoryComponent } from './locate-msg-history/locate-msg-history.component';
import { LocateMsgMapComponent } from './locate-msg-map/locate-msg-map.component';

@NgModule({
  declarations: [LoadCarrierMsgComponent, LocateMsgHistoryComponent, LocateMsgMapComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxSmartModalModule,
    ToastrModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyAgSoIu9Zn8QIZSLqgfok3wXR4TRcdPppY",
    }),
  ],
  exports: [LoadCarrierMsgComponent],
  entryComponents: [LoadCarrierMsgComponent],
})
export class LoadCarrierMsgModule {}
