import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CreateSaleComponent } from "./create-sale.component";
import { ReactiveFormsModule } from "@angular/forms";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { ToastrModule } from "ng6-toastr-notifications";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxLoadingModule, ngxLoadingAnimationTypes } from "ngx-loading";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { FileUploadModule } from "ng2-file-upload";

@NgModule({
  declarations: [CreateSaleComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxSmartModalModule,
    ToastrModule,
    NgSelectModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.wanderingCubes,
      backdropBorderRadius: "3px",
    }),
    BsDatepickerModule,
    FileUploadModule,
  ],
})
export class CreateSaleModule {}
