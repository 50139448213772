import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Globals } from "app/globals";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class MessagesService {
  constructor(private http: HttpClient, private globals: Globals) {}

  private PUBLIC_KEY = "fd44e8ae-4268-4330-bcb8-b62955c18a23";

  getLoad(load: string) {
    return this.http.get(`${this.globals.apiUrl}/messages/getLoad`, {
      params: { load },
    });
  }

  sendMessage(data) {
    return this.http.post(`${this.globals.apiUrl}/messages/send`, data, {
      headers: { TextLocate: this.PUBLIC_KEY },
    });
  }

  getPingHistory(load: string) {
    return this.http.get(
      `${this.globals.apiUrl}/messages/getPingHistory/${load}`,
      {
        headers: { TextLocate: this.PUBLIC_KEY },
      }
    );
  }
}
