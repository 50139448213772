import { Injectable } from "@angular/core";

@Injectable()
export class Globals {
  apiUrl: string = 'https://api.tmsarise.com';
  permisosdisponibles = [
    "ACCOUNTING",
    "BRANCHES",
    "CARRIERS",
    "COMMISSIONS",
    "CONFIGURATION",
    "CONSIGNEES",
    "CUSTOMERS",
    "DASHBOARD",
    "DASHBOARD REPORT",
    "FACTORING COMPANIES",
    "LOADS",
    "PRELOADS",
    "ADVANCE STADISTICS",
    "CUSTOMERS REPORT",
    "CARRIERS REPORT",
    "DISPATCHERS REPORT",
    "MANAGE REPORT",
    "OPERATORS REPORT",
    "RESET CARRIER INVOICE",
    "ROUTES MX",
    "SALES",
    "SALES PERSON",
    "SHIPPERS",
    "USERS",
    "WAREHOUSES",
    "WORK ORDERS",
    "LOGS",
    "CHANGE INVOICE DATE",
    "PRICING",
    "UPDATE CARRIER FEE AFTER COMPLETED",
    "CLONE LOADS",
    "ACTIVITY LOG",
  ];
  loadStatus = [
    "Open",
    "Covered",
    "Dispatched",
    "Loading",
    "On route",
    "Unloading",
    "In yard",
    "Delivered",
    "Completed",
    "Refused",
    "Pending",
    "Tonu",
    "At Customs",
    "Claim",
    "Invoiced",
  ];

  saleStatus = ["Not Completed", "Completed"];

  loadStatusClass = {
    Open: "open",
    Covered: "covered",
    Dispatched: "dispatched",
    Loading: "loading",
    "On route": "onRoute",
    Unloading: "unloading",
    "In yard": "inYard",
    Delivered: "delivered",
    Completed: "completed",
    Refused: "refused",
    Pending: "pending",
    Tonu: "tonu",
    "At Customs": "atCustoms",
    Claim: "claim",
    Invoiced: "invoiced",
  };
  hours = [
    { display: "00:00", hour: "00", minute: "00" },
    { display: "00:15", hour: "00", minute: "15" },
    { display: "00:30", hour: "00", minute: "30" },
    { display: "00:45", hour: "00", minute: "45" },
    { display: "01:00", hour: "01", minute: "00" },
    { display: "01:15", hour: "01", minute: "15" },
    { display: "01:30", hour: "01", minute: "30" },
    { display: "01:45", hour: "01", minute: "45" },
    { display: "02:00", hour: "02", minute: "00" },
    { display: "02:15", hour: "02", minute: "15" },
    { display: "02:30", hour: "02", minute: "30" },
    { display: "02:45", hour: "02", minute: "45" },
    { display: "03:00", hour: "03", minute: "00" },
    { display: "03:15", hour: "03", minute: "15" },
    { display: "03:30", hour: "03", minute: "30" },
    { display: "03:45", hour: "03", minute: "45" },
    { display: "04:00", hour: "04", minute: "00" },
    { display: "04:15", hour: "04", minute: "15" },
    { display: "04:30", hour: "04", minute: "30" },
    { display: "04:45", hour: "04", minute: "45" },
    { display: "05:00", hour: "05", minute: "00" },
    { display: "05:15", hour: "05", minute: "15" },
    { display: "05:30", hour: "05", minute: "30" },
    { display: "05:45", hour: "05", minute: "45" },
    { display: "06:00", hour: "06", minute: "00" },
    { display: "06:15", hour: "06", minute: "15" },
    { display: "06:30", hour: "06", minute: "30" },
    { display: "06:45", hour: "06", minute: "45" },
    { display: "07:00", hour: "07", minute: "00" },
    { display: "07:15", hour: "07", minute: "15" },
    { display: "07:30", hour: "07", minute: "30" },
    { display: "07:45", hour: "07", minute: "45" },
    { display: "08:00", hour: "08", minute: "00" },
    { display: "08:15", hour: "08", minute: "15" },
    { display: "08:30", hour: "08", minute: "30" },
    { display: "08:45", hour: "08", minute: "45" },
    { display: "09:00", hour: "09", minute: "00" },
    { display: "09:15", hour: "09", minute: "15" },
    { display: "09:30", hour: "09", minute: "30" },
    { display: "09:45", hour: "09", minute: "45" },
    { display: "10:00", hour: "10", minute: "00" },
    { display: "10:15", hour: "10", minute: "15" },
    { display: "10:30", hour: "10", minute: "30" },
    { display: "10:45", hour: "10", minute: "45" },
    { display: "11:00", hour: "11", minute: "00" },
    { display: "11:15", hour: "11", minute: "15" },
    { display: "11:30", hour: "11", minute: "30" },
    { display: "11:45", hour: "11", minute: "45" },
    { display: "12:00", hour: "12", minute: "00" },
    { display: "12:15", hour: "12", minute: "15" },
    { display: "12:30", hour: "12", minute: "30" },
    { display: "12:45", hour: "12", minute: "45" },
    { display: "13:00", hour: "13", minute: "00" },
    { display: "13:15", hour: "13", minute: "15" },
    { display: "13:30", hour: "13", minute: "30" },
    { display: "13:45", hour: "13", minute: "45" },
    { display: "14:00", hour: "14", minute: "00" },
    { display: "14:15", hour: "14", minute: "15" },
    { display: "14:30", hour: "14", minute: "30" },
    { display: "14:45", hour: "14", minute: "45" },
    { display: "15:00", hour: "15", minute: "00" },
    { display: "15:15", hour: "15", minute: "15" },
    { display: "15:30", hour: "15", minute: "30" },
    { display: "15:45", hour: "15", minute: "45" },
    { display: "16:00", hour: "16", minute: "00" },
    { display: "16:15", hour: "16", minute: "15" },
    { display: "16:30", hour: "16", minute: "30" },
    { display: "16:45", hour: "16", minute: "45" },
    { display: "17:00", hour: "17", minute: "00" },
    { display: "17:15", hour: "17", minute: "15" },
    { display: "17:30", hour: "17", minute: "30" },
    { display: "17:45", hour: "17", minute: "45" },
    { display: "18:00", hour: "18", minute: "00" },
    { display: "18:15", hour: "18", minute: "15" },
    { display: "18:30", hour: "18", minute: "30" },
    { display: "18:45", hour: "18", minute: "45" },
    { display: "19:00", hour: "19", minute: "00" },
    { display: "19:15", hour: "19", minute: "15" },
    { display: "19:30", hour: "19", minute: "30" },
    { display: "19:45", hour: "19", minute: "45" },
    { display: "20:00", hour: "20", minute: "00" },
    { display: "20:15", hour: "20", minute: "15" },
    { display: "20:30", hour: "20", minute: "30" },
    { display: "20:45", hour: "20", minute: "45" },
    { display: "21:00", hour: "21", minute: "00" },
    { display: "21:15", hour: "21", minute: "15" },
    { display: "21:30", hour: "21", minute: "30" },
    { display: "21:45", hour: "21", minute: "45" },
    { display: "22:00", hour: "22", minute: "00" },
    { display: "22:15", hour: "22", minute: "15" },
    { display: "22:30", hour: "22", minute: "30" },
    { display: "22:45", hour: "22", minute: "45" },
    { display: "23:00", hour: "23", minute: "00" },
    { display: "23:15", hour: "23", minute: "15" },
    { display: "23:30", hour: "23", minute: "30" },
    { display: "23:45", hour: "23", minute: "45" },
  ];

  equipamentTypes: any = [
    { value: `22' Van` },
    { value: `48' Reefer` },
    { value: `53' Reefer` },
    { value: `53' Van` },
    { value: `Air Freight` },
    { value: `Anhydros Ammonia` },
    { value: `Animal Carrier` },
    { value: `Any Equipment` },
    { value: `Any Equipment (Searching Services only)` },
    { value: `Auto Carrier` },
    { value: `B-Train/Supertrain` },
    { value: `B-Train/Supertrain (Canada only)` },
    { value: `Beam` },
    { value: `Belly Dump` },
    { value: `Blanket Wrap Van` },
    { value: `Boat Hualing Trailer` },
    { value: `Cargo Van (1 Ton)` },
    { value: `Cargo Vans (1 Ton capacity)` },
    { value: `Cargo/Small/Sprinter Van` },
    { value: `Conestoga` },
    { value: `Container Trailer` },
    { value: `Convertible Hopper` },
    { value: `Conveyot Belt` },
    { value: `Crane Truck` },
    { value: `Curtain Siders` },
    { value: `Curtain Van` },
    { value: `Double Drop` },
    { value: `Double Drop Extendable` },
    { value: `Drive Away` },
    { value: `Dump Trucks` },
    { value: `End Dump` },
    { value: `Flat Intermodal` },
    { value: `Flat with Tarps` },
    { value: `Flatbed` },
    { value: `Flatbed - Air-ride` },
    { value: `Flatbed Blanket Wrapped` },
    { value: `Flatbed Intermodal` },
    { value: `Flatbed or Step Deck` },
    { value: `Flatbed or Van` },
    { value: `Flatbed or Vented Van` },
    { value: `Flatbed Over-Dimension Loads` },
    { value: `Haul and Tow Unit` },
    { value: `Hazardous Material load` },
    { value: `Hopper Bottom` },
    { value: `Hot Shot` },
    { value: `Labour` },
    { value: `Landoll Flatbed` },
    { value: `Live Bottom Trailer` },
    { value: `Load-Out` },
    { value: `Load-Out are empty trailers you load and haul` },
    { value: `Lowboy` },
    { value: `Lowboy Over-Dimension Loads` },
    { value: `Maxl or Double Flat Trailers` },
    { value: `Mobile Home` },
    { value: `Moving Van` },
    { value: `Multi-Axle Heavy Hauler` },
    { value: `Ocean Freight` },
    { value: `Open Top` },
    { value: `Open Top Van` },
    { value: `Pneumatic` },
    { value: `Power Only` },
    { value: `Power Only (Tow-Away)` },
    { value: `Rall` },
    { value: `Reefer Pallet Exchange` },
    { value: `Refrigerated (Reefer)` },
    { value: `Refrigerated Carrier with Plant Decking` },
    { value: `Refrigerated Intermodal` },
    { value: `Removable Goose Neck` },
    { value: `Removable Goose Neck & Multi-Axie Heavy Hauliers` },
    { value: `RGN Extendable` },
    { value: `Roll Top Conestoga` },
    { value: `Roller Bed` },
    { value: `Specialized Trailers` },
    { value: `Step Deck` },
    { value: `Step Deck Conestoga` },
    { value: `Step Deck Extendable` },
    { value: `Step Deck or Flat` },
    { value: `Step Deck or Removable Gooseneck` },
    { value: `Step Deck Over-Dimension Loads` },
    { value: `Step Deck with Loading Ramps` },
    { value: `Straight Van` },
    { value: `Strech Trailer or Ext. Flat` },
    { value: `Strech Trailer or Extendable Flatbed` },
    { value: `Supplies` },
    { value: `Tandem Van` },
    { value: `Tanker` },
    { value: `Tanker (Food grade, liquid, bulk, etc.)` },
    { value: `Team Driver Needed` },
    { value: `Tridem` },
    { value: `Two 24 or 28 Foot Flatbeds` },
    { value: `Unspecified Specialized Trailers` },
    { value: `Van` },
    { value: `Van - Air-ride` },
    { value: `Van Intermodal` },
    { value: `Van or Flatbed` },
    { value: `Van Pallet Exchange` },
    { value: `Van with Liftgate` },
    { value: `Van, Reefer or Double Drop` },
    { value: `Vented Insulated Van` },
    { value: `Vented Insulated Van or Refrigerated` },
    { value: `Vented Van` },
    { value: `Vented Van or Refrigerated` },
    { value: `Walking Floor` },
  ];

  paymentMethods: any = [
    { value: "Check" },
    { value: "ACH" },
    { value: "Wire" },
  ];

  states: any = {
    USA: [
      { code: "AL", state: "Alabama" },
      { code: "AK", state: "Alaska" },
      { code: "AZ", state: "Arizona" },
      { code: "AR", state: "Arkansas" },
      { code: "CA", state: "California" },
      { code: "CO", state: "Colorado" },
      { code: "CT", state: "Connecticut" },
      { code: "DE", state: "Delaware" },
      { code: "DC", state: "District of Columbia" },
      { code: "FL", state: "Florida" },
      { code: "GA", state: "Georgia" },
      { code: "HI", state: "Hawaii" },
      { code: "ID", state: "Idaho" },
      { code: "IL", state: "Illinois" },
      { code: "IN", state: "Indiana" },
      { code: "IA", state: "Iowa" },
      { code: "KS", state: "Kansas" },
      { code: "KY", state: "Kentucky" },
      { code: "LA", state: "Louisiana" },
      { code: "ME", state: "Maine" },
      { code: "MD", state: "Maryland" },
      { code: "MA", state: "Massachusetts" },
      { code: "MI", state: "Michigan" },
      { code: "MN", state: "Minnesota" },
      { code: "MS", state: "Mississippi" },
      { code: "MO", state: "Missouri" },
      { code: "MT", state: "Montana" },
      { code: "NE", state: "Nebraska" },
      { code: "NV", state: "Nevada" },
      { code: "NH", state: "New Hampshire" },
      { code: "NJ", state: "New Jersey" },
      { code: "NM", state: "New Mexico" },
      { code: "NY", state: "New York" },
      { code: "NC", state: "North Carolina" },
      { code: "ND", state: "North Dakota" },
      { code: "OH", state: "Ohio" },
      { code: "OK", state: "Oklahoma" },
      { code: "OR", state: "Oregon" },
      { code: "PA", state: "Pennsylvania" },
      { code: "RI", state: "Rhode Island" },
      { code: "SC", state: "South Carolina" },
      { code: "SD", state: "South Dakota" },
      { code: "TN", state: "Tennessee" },
      { code: "TX", state: "Texas" },
      { code: "UT", state: "Utah" },
      { code: "VT", state: "Vermont" },
      { code: "VA", state: "Virginia" },
      { code: "WA", state: "Washington" },
      { code: "WV", state: "West Virginia" },
      { code: "WI", state: "Wisconsin" },
      { code: "WY", state: "Wyoming" },
    ],
    MX: [
      { state: "Aguascalientes", code: "AG" },
      { state: "Baja California", code: "BC" },
      { state: "Baja California Sur", code: "BS" },
      { state: "Campeche", code: "CM" },
      { state: "Chiapas", code: "CS" },
      { state: "Chihuahua", code: "CH" },
      { state: "Ciudad de México", code: "CX" },
      { state: "Coahuila", code: "CO" },
      { state: "Colima", code: "CL" },
      { state: "Durango", code: "DG" },
      { state: "Guanajuato", code: "GT" },
      { state: "Guerrero", code: "GR" },
      { state: "Hidalgo", code: "HG" },
      { state: "Jalisco", code: "JC" },
      { state: "Estado de México", code: "EM" },
      { state: "Michoacán", code: "MI" },
      { state: "Morelos", code: "MO" },
      { state: "Nayarit", code: "NA" },
      { state: "Nuevo León", code: "NL" },
      { state: "Oaxaca", code: "OA" },
      { state: "Puebla", code: "PU" },
      { state: "Querétaro", code: "QT" },
      { state: "Quintana Roo", code: "QR" },
      { state: "San Luis Potosí", code: "SL" },
      { state: "Sinaloa", code: "SI" },
      { state: "Sonora", code: "SO" },
      { state: "Tabasco", code: "TB" },
      { state: "Tamaulipas", code: "TM" },
      { state: "Tlaxcala", code: "TL" },
      { state: "Veracruz", code: "VE" },
      { state: "Yucatán", code: "YU" },
      { state: "Zacatecas", code: "ZA" },
    ],
    CA: [
      { state: "Alberta", code: "AB" },
      { state: "British Columbia", code: "BC" },
      { state: "Manitoba", code: "MB" },
      { state: "New Brunswick", code: "NB" },
      { state: "Newfoundland", code: "NL" },
      { state: "Northwest Territories", code: "NT" },
      { state: "Nova Scotia", code: "NS" },
      { state: "Nunavut", code: "NU" },
      { state: "Ontario", code: "ON" },
      { state: "Prince Edward Island", code: "PE" },
      { state: "Quebec", code: "QC" },
      { state: "Saskatchewan", code: "SK" },
      { state: "Yukon", code: "YT" },
    ],
  };

  filterShipperFn(term: string, item: any) {
    term = term.toLowerCase();
    return (
      item.name.toLowerCase().indexOf(term) != -1 ||
      item.location.toLowerCase().indexOf(term) != -1
    );
  }
  filterConsigneeFn(term: string, item: any) {
    term = term.toLowerCase();
    return (
      item.name.toLowerCase().indexOf(term) != -1 ||
      item.location.toLowerCase().indexOf(term) != -1
    );
  }
  filterCarrierFn(term: string, item: any) {
    term = term.toLowerCase();
    return (
      item.name.toLowerCase().indexOf(term) != -1 ||
      item.address.toLowerCase().indexOf(term) != -1 ||
      item.mc_number.toLowerCase().indexOf(term) != -1
    );
  }
  filterWarehouseFn(term: string, item: any) {
    term = term.toLowerCase();
    return (
      item.name.toLowerCase().indexOf(term) != -1 ||
      item.location.toLowerCase().indexOf(term) != -1
    );
  }
  getRowClass(row) {
    return {
      cancelled: row.status == "inactivo",
    };
  }
}
