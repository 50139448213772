import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ViewIncomingLoadModalComponent } from "./view-incoming-load-modal.component";
import { QuillModule } from "ngx-quill";
import { NgxSmartModalModule } from "ngx-smart-modal";

@NgModule({
  declarations: [ViewIncomingLoadModalComponent],
  imports: [CommonModule, QuillModule.forRoot(), NgxSmartModalModule],
})
export class ViewIncomingLoadModalModule {}
