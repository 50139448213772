import { Component, OnInit } from "@angular/core";
import { NgxPermissionsService } from "ngx-permissions";
import { Router } from "@angular/router";
import { FiltersService } from "./services/filters.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  constructor(
    private permissionsService: NgxPermissionsService,
    private router: Router,
    private _filters: FiltersService
  ) {}

  ngOnInit() {
    if (localStorage.getItem("jwtToken")) {
      var token = JSON.parse(localStorage.getItem("jwtToken"));
      const perm = token.user.permisos;
      if (perm) this.permissionsService.loadPermissions(perm);
      // this.router.navigate(['/dashboard']);
      //RENOVAR TOKEN SI YA VENCIO
    }
  }
}
