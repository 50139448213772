import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Globals } from './../globals';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PreloadService {

  constructor(private http: HttpClient, private globals: Globals, private router: Router) {
  }

  getById(id) {
    return this.http.get(`${this.globals.apiUrl}/preload/getById`, {
      params: {
        id: id
      }
    }).pipe(catchError(this.handleError));
  }

  addPreLoad(preload) {
    return this.http.post(`${this.globals.apiUrl}/preload/addPreLoad`, {
      preload: preload
    }).pipe(catchError(this.handleError));
  }

  addOffer(offer) {
    return this.http.post(`${this.globals.apiUrl}/preload/addOffer`, {
      offer: offer
    }).pipe(catchError(this.handleError));
  }

  addCarrier(carrier) {
    return this.http.post(`${this.globals.apiUrl}/preload/addCarrier`, {
      carrier: carrier
    }).pipe(catchError(this.handleError));
  }

  addShippers(shipper) {
    return this.http.post(`${this.globals.apiUrl}/loads/addShippers`, {
      shipper: shipper
    }).pipe(catchError(this.handleError));
  }

  addConsignee(consignee) {
    return this.http.post(`${this.globals.apiUrl}/loads/addConsignees`, {
      consignee: consignee
    }).pipe(catchError(this.handleError));
  }

  addWerehouse(werehouse) {
    return this.http.post(`${this.globals.apiUrl}/loads/addWerehouse`, {
      werehouse: werehouse
    }).pipe(catchError(this.handleError));
  }

  editPreload(preload) {
    return this.http.post(`${this.globals.apiUrl}/preload/editPreload`, {
      preload: preload
    }).pipe(catchError(this.handleError));
  }
  updatePreload(preload) {
    return this.http.post(`${this.globals.apiUrl}/preload/updatePreload`, {
      preload: preload
    }).pipe(catchError(this.handleError));
  }

  editShipper(shipper) {
    return this.http.post(`${this.globals.apiUrl}/loads/editShipper`, {
      shipper: shipper
    }).pipe(catchError(this.handleError));
  }

  editConsignee(consignee) {
    return this.http.post(`${this.globals.apiUrl}/loads/editConsignee`, {
      consignee: consignee
    }).pipe(catchError(this.handleError));
  }

  delete(id) {
    return this.http.post(`${this.globals.apiUrl}/preload/delete`, {
      id: id
    }).pipe(catchError(this.handleError));
  }

  resetPreload(id) {
    return this.http.post(`${this.globals.apiUrl}/preload/resetPreload`, {
      id: id
    }).pipe(catchError(this.handleError));
  }

  getAll(filterObj) {
    return this.http.post(`${this.globals.apiUrl}/preload/getAll`, {
      filterObj
    }).pipe(catchError(this.handleError));
  }

  getPreload(preload) {
    return this.http.post(`${this.globals.apiUrl}/preload/getPreload`, {
      preload: preload
    }).pipe(catchError(this.handleError));
  }

  getLastNumbers() {
    return this.http.get(`${this.globals.apiUrl}/loads/getLastNumbers`).pipe(catchError(this.handleError));
  }

  getShippers() {
    return this.http.get(`${this.globals.apiUrl}/loads/getShippers`).pipe(catchError(this.handleError));
  }

  getConsignees() {
    return this.http.get(`${this.globals.apiUrl}/loads/getConsignees`).pipe(catchError(this.handleError));
  }

  getWerehouses() {
    return this.http.get(`${this.globals.apiUrl}/loads/getWerehouses`).pipe(catchError(this.handleError));
  }

  // NO EN USO TODAVIA EN EL NEW PRELOAD
  searchShippers(search, type) {
    if (search) {
      return this.http.get<any>(`${this.globals.apiUrl}/loads/searchShippers`, { params: {search: search, type: type }}).pipe(map(rsp => rsp.shippers));
    } else {
      return of([]);
    }
  }

  searchConsignees(search, type) {
    if (search) {
      return this.http.get<any>(`${this.globals.apiUrl}/loads/searchConsignees`, { params: {search: search, type: type }}).pipe(map(rsp => rsp.consignees));
    } else {
      return of([]);
    }
  }

  searchWarehouses(search) {
    if (search) {
      return this.http.get<any>(`${this.globals.apiUrl}/loads/searchWarehouses`, { params: {search: search }}).pipe(map(rsp => rsp.warehouses));
    } else {
      return of([]);
    }
  }

  add(load) {
    return this.http.post(`${this.globals.apiUrl}/loads/add`, {
      load: load
    }).pipe(catchError(this.handleError));
  }

  getLoadByPreload(id) {
    return this.http.get(`${this.globals.apiUrl}/preload/getLoadByPreload`, {
      params: {
        id
      }
    }).pipe(catchError(this.handleError));
  }

  updateNotes(notes, id) {
    return this.http.post(`${this.globals.apiUrl}/preload/updateNotes`, {
      notes: notes,
      id: id
    }).pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(`Backend returned error ${error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  };
}
