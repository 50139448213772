import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DispatchersService } from "app/services/dispatchers.service";
import { IncomingLoadsService } from "app/services/incoming-loads.service";
import { OperatorsService } from "app/services/operators.service";
import { UsuariosService } from "app/services/usuarios.service";
import { ToastrManager } from "ng6-toastr-notifications";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Subject, of } from "rxjs";
import { finalize, switchMap, takeUntil, tap } from "rxjs/operators";

export const CREATE_INCOMING_LOAD_MODAL_ID = "CREATE_INCOMING_LOAD_MODAL";

@Component({
  selector: "app-create-incoming-load-modal",
  templateUrl: "./create-incoming-load-modal.component.html",
  styleUrls: ["./create-incoming-load-modal.component.css"],
})
export class CreateIncomingLoadModalComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<void>();

  private readonly data$ = (id: number) =>
    of({}).pipe(
      tap(() => (this.loading = true)),
      switchMap(() => this._incomingLoads.getById(id)),
      takeUntil(this.onDestroy$),
      finalize(() => (this.loading = false))
    );

  private readonly create$ = of({}).pipe(
    tap(() => (this.requesting = true)),
    switchMap(() => {
      if (this.form.invalid) throw "Invalid form.";
      return this._incomingLoads.add({
        ...this.form.value,
        user: this._usuarios.getCurrentUser().id,
        title: encodeURIComponent(this.form.value.title),
      });
    }),
    finalize(() => (this.requesting = false)),
    takeUntil(this.onDestroy$)
  );

  private readonly update$ = of({}).pipe(
    tap(() => (this.requesting = true)),
    switchMap(() => {
      if (this.form.invalid) throw "Invalid form.";
      return this._incomingLoads.update(this.id, {
        ...this.form.value,
        title: encodeURIComponent(this.form.value.title),
      });
    }),
    finalize(() => (this.requesting = false)),
    takeUntil(this.onDestroy$)
  );

  public readonly operators$ = this._operators.getAll();
  public readonly dispatchers$ = this._dispatchers.getAll();

  public form: FormGroup;
  public action: "add" | "edit";
  public id: number;

  public loading: boolean;
  public requesting: boolean;

  constructor(
    private fb: FormBuilder,
    private _incomingLoads: IncomingLoadsService,
    private _operators: OperatorsService,
    private _dispatchers: DispatchersService,
    private _toast: ToastrManager,
    private _modal: NgxSmartModalService,
    private _usuarios: UsuariosService
  ) {}

  get disabled() {
    return this.form.invalid || this.requesting;
  }

  ngOnInit() {
    this.form = this.fb.group({
      title: [null, [Validators.required]],
      date: [null, [Validators.required]],
      operator: [null, [Validators.required]],
      dispatcher: [null],
      covered: [false, [Validators.required]],
      standBy: [false, [Validators.required]],
      multiplePO: [false, [Validators.required]],
      description: [null, []],
    });
    this.getData();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  initListeners() {}

  getData() {
    const { date, action, id } = this._modal
      .get(CREATE_INCOMING_LOAD_MODAL_ID)
      .getData();
    this.action = action;
    if (action === "edit" && id) {
      this.id = id;
      this.data$(id).subscribe((data) => {
        data.date = new Date(data.date);
        data.title = decodeURIComponent(data.title);
        data.operator = data.operator ? data.operator.id : null;
        data.dispatcher = data.dispatcher ? data.dispatcher.id : null;
        this.form.patchValue(data);
      });
    } else if (date) this.form.patchValue({ date });
  }

  onSubmit() {
    if (this.action == "edit") this.edit();
    else this.create();
  }

  create() {
    this.create$.subscribe(
      () => {
        this._toast.successToastr("Scheduled load created succefully.");
        this._modal
          .get(CREATE_INCOMING_LOAD_MODAL_ID)
          .removeData()
          .setData({ updated: true })
          .close();
      },
      (error) => this._toast.errorToastr(error)
    );
  }

  edit() {
    this.update$.subscribe(
      () => {
        this._toast.successToastr("Scheduled load updated succefully.");
        this._modal
          .get(CREATE_INCOMING_LOAD_MODAL_ID)
          .removeData()
          .setData({ updated: true })
          .close();
      },
      (error) => this._toast.errorToastr(error)
    );
  }

  //Utils
  invalidControl(name: string) {
    return this.form.get(name).invalid && this.form.get(name).touched;
  }
}
