import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
} from "@angular/forms";
import { CustomersService } from "app/services/customers.service";
import { DispatchersService } from "app/services/dispatchers.service";
import { PreloadService } from "app/services/preload.service";
import { NgxSmartModalComponent, NgxSmartModalService } from "ngx-smart-modal";
import { concat, Observable, of, Subject } from "rxjs";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  switchMap,
  tap,
} from "rxjs/operators";
import { ToastrManager } from "ng6-toastr-notifications";
import { Globals } from "app/globals";
import { OperatorsService } from "app/services/operators.service";
import { LoadsService } from "app/services/loads.service";
import * as _ from "lodash";
import { UsuariosService } from "app/services/usuarios.service";
import { PreloadFiltersService } from "app/services/preload-filters.service";

export const PRELOADS_FILTERS_MODAL = "PRELOADS_FILTERS_MODAL";

@Component({
  selector: "app-preloads-filters",
  templateUrl: "./preloads-filters.component.html",
  styleUrls: ["./preloads-filters.component.css"],
})
export class PreloadsFiltersComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private _customers: CustomersService,
    private _dispatchers: DispatchersService,
    private _users: UsuariosService,
    private _operators: OperatorsService,
    private _preloads: PreloadService,
    private _toastr: ToastrManager,
    private globals: Globals,
    public _loads: LoadsService,
    private _filters: PreloadFiltersService,
    private _modal: NgxSmartModalService
  ) {}

  private readonly onDestroy$ = new Subject<any>();

  @Output("getValues") private getValues = new EventEmitter<any>();
  @ViewChild("filtersModal") public filtersModal: NgxSmartModalComponent;

  public readonly status = [
    { value: "activo", label: "Active" },
    { value: null, label: "All" },
  ];

  public readonly convert = [
    { value: "all", label: "All" },
    { value: false, label: "Preload" },
    { value: true, label: "Load" },
  ];

  public shippersCountries = this._loads.getShippersCountries();
  public consigneesCountries = this._loads.getConsigneesCountries();

  public filtersForm: FormGroup;

  private toastOpts = { maxShown: 1, animate: "slideFromBottom" };

  public readonly models = {
    consignees: {
      items$: new Observable<any[]>(),
      loading: false,
      input$: new Subject<string>(),
      req$: (keyword) =>
        this._preloads
          .searchConsignees(keyword, "accounting")
          .pipe(map((data) => this.mapDestOrigin(data))),
      initValue: this._filters.extraData["destinationConsignees"]
        ? [this._filters.extraData["destinationConsignees"]]
        : null,
    },
    consigneesCities: {
      items$: new Observable<any[]>(),
      loading: false,
      input$: new Subject<string>(),
      req$: (keyword) => this._loads.searchConsigneesCities(keyword),
      initValue: this._filters.extraData["destinationCity"],
    },
    customers: {
      items$: new Observable<any[]>(),
      loading: false,
      input$: new Subject<string>(),
      req$: (keyword) => this._customers.search(keyword),
      initValue: this._filters.extraData["customers"],
    },
    dispatchers: {
      items$: new Observable<any[]>(),
      loading: false,
      input$: new Subject<string>(),
      req$: (keyword) => this._dispatchers.search(keyword),
      initValue: this._filters.extraData["dispatcher"],
    },
    managers: {
      items$: new Observable<any[]>(),
      loading: false,
      input$: new Subject<string>(),
      req$: (keyword) => this._users.searchManagersUsers(keyword),
      initValue: this._filters.extraData["manager"]
        ? this._filters.extraData["manager"]
        : null,
    },
    operators: {
      items$: new Observable<any[]>(),
      loading: false,
      input$: new Subject<string>(),
      req$: (keyword) => this._operators.search(keyword),
      initValue: this._filters.extraData["operator"]
        ? this._filters.extraData["operator"]
        : null,
    },
    shippers: {
      items$: new Observable<any[]>(),
      loading: false,
      input$: new Subject<string>(),
      req$: (keyword) =>
        this._preloads
          .searchShippers(keyword, "accounting")
          .pipe(map((data) => this.mapDestOrigin(data))),
      initValue: this._filters.extraData["originShippers"]
        ? [this._filters.extraData["originShippers"]]
        : null,
    },
    shippersCities: {
      items$: new Observable<any[]>(),
      loading: false,
      input$: new Subject<string>(),
      req$: (keyword) => this._loads.searchShippersCities(keyword),
      initValue: this._filters.extraData["originCity"],
    },
  };

  public readonly equipmentTypes = this.globals.equipamentTypes;

  ngOnInit(): void {
    this.filtersForm = this.fb.group({
      poNumber: [null],
      equipmentType: [null],
      status: [null],
      convert: [null],
      operator: [null],
      dispatcher: [null],
      manager: [null],
      customers: [null],
      originShippers: [null],
      originCity: [null],
      originCountry: [null],
      destinationConsignees: [null],
      destinationCity: [null],
      destinationCountry: [null],
    });
    this.initFilters();
    this.initListeners();
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.unsubscribe();
  }

  //Filters
  private initFilters() {
    if (!this._filters.filters) return;
    this.filtersForm.patchValue(this._filters.filters);
  }

  //Listeners
  public initListeners() {
    this.initSearchers();
  }

  public initSearchers() {
    Object.keys(this.models).forEach((key) => {
      this.models[key].items$ = concat(
        of(this.models[key].initValue || []),
        this.models[key].input$.pipe(
          distinctUntilChanged(),
          debounceTime(500),
          tap(() => (this.models[key].loading = true)),
          switchMap((term) =>
            this.models[key].req$(term).pipe(
              catchError(() => of([])),
              finalize(() => (this.models[key].loading = false))
            )
          )
        )
      );
    });
  }

  public onSelectChange(type: string, event: any) {
    this._filters.extraData = { ...this._filters.extraData, [type]: event };
  }

  //Values
  public sendValues() {
    if (this.filtersForm.invalid)
      return this._toastr.errorToastr("Invalid Form", "", this.toastOpts);
    //Send
    this._filters.filters = this.filtersForm.value;
    this.getValues.emit(this.filtersForm.value);
    this._modal.get(PRELOADS_FILTERS_MODAL).close();
  }
  //Validators

  //Maps
  private mapDestOrigin(data: any[]) {
    return data.map((e) => {
      e.cityFormat = `${e.city ? e.city : "?"}, ${e.state ? e.state : "?"}`;
      e.cityFormatLbl = `, ${e.city ? e.city : "?"}, ${
        e.state ? e.state : "?"
      }`;
      return e;
    });
  }

  //Utils
  public resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      status: "activo",
      convert: "all",
    });
    this._filters.extraData = {};
  }

  filterCitiesFn(term: string, item: any) {
    term = term.toLowerCase();
    return item.city.toLowerCase().indexOf(term) != -1;
  }
  filterShipperFn(term: string, item: any) {
    term = term.toLowerCase();
    return (
      item.name.toLowerCase().indexOf(term) != -1 ||
      item.city.toLowerCase().indexOf(term) != -1
    );
  }
  filterConsigneeFn(term: string, item: any) {
    term = term.toLowerCase();
    return (
      item.name.toLowerCase().indexOf(term) != -1 ||
      item.city.toLowerCase().indexOf(term) != -1
    );
  }
}
