import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { CalendarModalComponent } from "./calendar-modal.component";
import { FormsModule } from "@angular/forms";
import { NgxSmartModalModule } from "ngx-smart-modal";

@NgModule({
  declarations: [CalendarModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgxSmartModalModule,
  ],
})
export class CalendarModalModule {}
