import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TextlocateComponent } from "./textlocate.component";
import { TextlocateHistoryComponent } from "./textlocate-history/textlocate-history.component";
import { TextlocateLocateComponent } from "./textlocate-locate/textlocate-locate.component";
import { TextlocateMapComponent } from "./textlocate-map/textlocate-map.component";
import { AgmCoreModule } from "@agm/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { ToastrModule } from "ng6-toastr-notifications";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { NgxLoadingModule, ngxLoadingAnimationTypes } from "ngx-loading";

@NgModule({
  declarations: [
    TextlocateComponent,
    TextlocateHistoryComponent,
    TextlocateLocateComponent,
    TextlocateMapComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxSmartModalModule,
    ToastrModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyAgSoIu9Zn8QIZSLqgfok3wXR4TRcdPppY",
    }),
    NgxDatatableModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.wanderingCubes,
      backdropBorderRadius: "3px",
    }),
  ],
  entryComponents: [TextlocateComponent],
  exports: [TextlocateComponent],
})
export class TextlocateModule {}
