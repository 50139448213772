import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AttachFilesModalComponent } from "./attach-files-modal.component";
import { ToastrModule } from "ng6-toastr-notifications";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { FileUploadModule } from "ng2-file-upload";
import { ngxLoadingAnimationTypes, NgxLoadingModule } from "ngx-loading";
import { VisualizePdfModule } from "app/shared/visualize-pdf/visualize-pdf.module";
import { PdfModalModule } from "../pdf-modal/pdf-modal.module";

@NgModule({
  declarations: [AttachFilesModalComponent],
  imports: [
    CommonModule,
    ToastrModule.forRoot(),
    NgxSmartModalModule,
    FileUploadModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.wanderingCubes,
      backdropBorderRadius: "3px",
    }),
    VisualizePdfModule,
    PdfModalModule,
  ],
  exports: [AttachFilesModalComponent],
  entryComponents: [AttachFilesModalComponent],
})
export class AttachFilesModalModule {}
